import React, { FC, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Avatar,
  Typography,
  TextField,
  CircularProgress,
  Button,
  Grid,
  Link,
  Box,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

// import images
import mazdaLogo from "../images/mazdaincredibleLab_rogo2.png";
import arithmerLogo from "../images/arithmer_logo.png";
import youtubeLogo from "../images/developed-with-youtube-sentence-case-dark.png";

// import my modules
import { Store } from "../store";
import { FeedbackBar } from "../utils/feedback";
import { auth } from "../utils/firebase";
import types from "../store/types";
import { setSignInState } from "../store/actions";
import FooterContent from "../components/footer";
import { VideoCardProps } from "../components/common/videoCard";

const mazdaURL = () => {
  window.open("https://mazda-lab.co.jp/");
};
const arithmerURL = () => {
  window.open("https://arithmer.co.jp/");
};
const youtubeURL = () => {
  window.open("https://www.youtube.com/");
};

const useStyles = makeStyles((theme) => ({
  mainForm: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mazda_logo: {
    width: "200px",
    marginTop: theme.spacing(8),
    cursor: "pointer",
  },
  arith_logo: {
    width: "120px",
    marginTop: theme.spacing(2),
    cursor: "pointer",
  },
  youtube_logo: {
    width: "300px",
    marginTop: theme.spacing(2),
    cursor: "pointer",
  },
  policy: { cursor: "pointer" },
  centering: { textAlign: "center" },
  newAccount: { textAlign: "end" },
}));

const LoginPage: FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(Store);
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signInValid, setSignInValid] = useState(true);
  const [isSending, setIsSending] = useState(false);

  // handle functions
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPassword(e.target.value);
  };
  const checkInput = () => {
    if (email.length !== 0 && password.length !== 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleSignInButton = async () => {
    try {
      setIsSending(true);
      await auth.signInWithEmailAndPassword(email, password);
      dispatch({
        type: types.SET_SIGNIN_STATE,
        payload: {
          signInState: "",
        },
      });
      setIsSending(false);
    } catch (e) {
      setSignInValid(false);
      setIsSending(false);
    }
  };
  const handleCreateAccount = () => {
    history.push("/mail-register");
  };
  const handleForgetPassword = () => {
    history.push("/reset-password");
  };

  const resetRecommendDataResult = () => {
    dispatch({
      type: types.SET_RECOMMEND_DATA,
      payload: {
        recommendReview: "",
        recommendCategory: "",
        recommendWeight: 0,
      },
    });
    dispatch({
      type: types.SET_RECOMMEND_RESULT,
      payload: {
        recommendResult: Array<VideoCardProps>(),
      },
    });
  };

  // if SignInState is "SignIn", move to main page
  useEffect(() => {
    if (state.signInState === "SignIn") {
      // ログイン時、レコメンド条件と結果をクリアしておく
      resetRecommendDataResult();
      history.push("/main");
    } else if (state.signInState === "EmailVerified") {
      history.push("/register");
    }
  }, [state.signInState]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div className={classes.mainForm}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ログイン
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="メールアドレス"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={onChangeEmail}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="password"
              value={password}
              onChange={onChangePassword}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !checkInput()) {
                  // エンターキー押下時，ログインボタンを押すのと同じ動作をする
                  handleSignInButton();
                }
              }}
            />
            {!signInValid ? (
              <div className={classes.centering}>
                <Typography color="error">
                  入力された情報が正しくありません
                </Typography>
                <Typography color="error">
                  確認してから再度入力をお試しください
                </Typography>
              </div>
            ) : (
              <></>
            )}
            <Button
              className={classes.submit}
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              disabled={checkInput() || isSending}
              onClick={handleSignInButton}
            >
              ログイン
            </Button>
            <div className={classes.centering}>
              {isSending ? <CircularProgress size={60} /> : <></>}
            </div>
            <Grid container>
              <Grid item xs={6}>
                <Box fontWeight="bold" color={grey[500]}>
                  <Link
                    color="inherit"
                    className={classes.policy}
                    onClick={handleForgetPassword}
                  >
                    パスワードを忘れた場合
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  fontWeight="bold"
                  color={grey[500]}
                  className={classes.newAccount}
                >
                  <Link
                    color="inherit"
                    className={classes.policy}
                    onClick={handleCreateAccount}
                  >
                    新規登録はこちら
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </form>
          <img
            src={mazdaLogo}
            className={classes.mazda_logo}
            onClick={mazdaURL}
            alt="mazda lab"
          />
          <img
            src={arithmerLogo}
            className={classes.arith_logo}
            onClick={arithmerURL}
            alt="arithmer"
          />
          <img
            src={youtubeLogo}
            className={classes.youtube_logo}
            onClick={youtubeURL}
            alt="arithmer"
          />
        </div>
      </Container>
      <FooterContent />
    </>
  );
};

export default LoginPage;
