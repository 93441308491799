// ポートフォリオの画面
import React, { FC, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Slider,
  Paper,
  Link,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-date-picker";
import queryString from "query-string";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";

// self modules
import { Store } from "../store";
import CategoryNestedPulldown from "../components/common/category";
import VideoCardDetail, {
  VideoCardDetailProps,
} from "../components/portfolio/videoCardDetail";
import {
  getReviews,
  savePortfolio,
  getPortfolio,
} from "../utils/http-requests";
import { FeedbackBar } from "../utils/feedback";
import { portfolioInformation } from "../utils/types";

const useStyles = makeStyles({
  centering: {
    textAlign: "center",
  },
  pageTitle: {
    textAlign: "center",
    paddingTop: "1em",
  },
  textGrey: {
    color: "#616161",
  },
  scrollable: {
    overflowY: "scroll",
    height: "33rem",
    marginRight: "2rem",
    backgroundColor: "#fafafa",
  },
  mainContent: {
    marginBottom: "1rem",
  },
  root: {
    "&$disabled": {
      color: "#000000",
      backgroundColor: "#ffffff",
    },
  },
  disabled: {},
  reviewInput: {
    marginTop: "1rem",
  },
  movePage: {
    cursor: "pointer",
    paddingTop: "2rem",
    color: "#616161",
  },
  saveButton: {
    paddingTop: "1rem",
    textAlign: "center",
  },
  tooltipText: {
    fontSize: "18px",
  },
  tooltipTitle: {
    fontSize: "21px",
    textAlign: "center",
  },
  customTooltipContinueLearning: {
    minWidth: 500,
    minHeight: 300,
    marginBottom: "50px",
  },
  customTooltipSelfLearning: {
    minWidth: 500,
    minHeight: 200,
    marginBottom: "50px",
  },
});


interface VideoQueryData {
  dateFrom: Date;
  dateTo: Date;
  category: string;
}

const PortfolioPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useContext(Store);
  const [videoQuery, setVideoQuery] = useState<VideoQueryData>({
    dateFrom: new Date(),
    dateTo: new Date(),
    category: "",
  });
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const [title, setTitle] = useState("");
  const [review, setReview] = useState("");
  const [scoreContinueLearning, setScoreContinueLearning] = useState(50);
  const [wordContinueLearning, setWordContinueLearning] = useState(""); //TODO: これuseState使わなくていいかも
  const [scoreSelfLearning, setScoreSelfLearning] = useState(50);
  const [wordSelfLearning, setWordSelfLearning] = useState(""); //TODO: これuseState使わなくていいかも
  const [portfolioId, setPortfolioId] = useState("");
  const [userId, setUserId] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [viewTime, setViewTime] = useState(0);
  const max_title_length = 20;
  const max_review_length = 3000;
  const [reviewHistory, setReviewHistory] = useState(
    Array<VideoCardDetailProps>()
  );
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  // ------ Feedback Bar setting -----
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null as number | null,
  });
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };
  // ---------------------------------

  // youtubeの動画のタグをURLパラメータから取得
  useEffect(() => {
    const writeSetPortfolioData = (data: any) => {
      setTitle(data.title);
      setVideoQuery({
        dateFrom: new Date(data.dateFrom),
        dateTo: new Date(data.dateTo),
        category: data.category,
      });
      setReview(data.review);
      setScoreContinueLearning(data.scoreContinueLearning);
      setScoreSelfLearning(data.scoreSelfLearning);
    };

    const f = async () => {
      // URLパラメータを取得する（qs.id：ユーザID / qs.d：ポートフォリオID）
      const qs = queryString.parse(history.location.search);
      if (qs.id && qs.d) {
        setIsUpdate(true);
        setPortfolioId(String(qs.d));
        setUserId(String(qs.id));
        let { content, targetFullname } = await getPortfolio(String(qs.d), String(qs.id));
        writeSetPortfolioData(content);
      } else if (qs.d) {
        setIsUpdate(true);
        setPortfolioId(String(qs.d));
        let { content, targetFullname } = await getPortfolio(String(qs.d));
        writeSetPortfolioData(content);
      }
    };
    f();
  }, []);

  // 時間指定の変更に応じた関数
  useEffect(() => {
    const f = async () => {
      const qs = queryString.parse(history.location.search);
      const result = await getReviews(
        videoQuery.dateFrom,
        videoQuery.dateTo,
        videoQuery.category,
        qs.id ? String(qs.id) : undefined
      );
      // 総視聴時間の計算
      const t = result.reduce((sum, videos) => sum + (videos.viewTime || 0), 0);
      // ミリ秒から分に変換して格納
      setViewTime(Math.floor(t / 1000 / 60));
      setReviewHistory(result);
      setIsChanged(true);
    };
    if (videoQuery.dateFrom && videoQuery.dateTo && videoQuery.category) {
      f();
    }
  }, [videoQuery]);

  //自己評価の点数に応じた評価文(粘り強さ)
  useEffect(() => {
    if (scoreContinueLearning < 15) {
      setWordContinueLearning("まったくなかった");
    } else if (scoreContinueLearning < 29) {
      setWordContinueLearning("あまりなかった");
    } else if (scoreContinueLearning < 43) {
      setWordContinueLearning("少しなかった");
    } else if (scoreContinueLearning < 57) {
      setWordContinueLearning("ふつう");
    } else if (scoreContinueLearning < 71) {
      setWordContinueLearning("少しはあった");
    } else if (scoreContinueLearning < 85) {
      setWordContinueLearning("まあまああった");
    } else {
      setWordContinueLearning("とてもあった");
    }
  }, [scoreContinueLearning]);

  //自己評価の点数に応じた評価文(自己調整)
  useEffect(() => {
    if (scoreSelfLearning < 15) {
      setWordSelfLearning("全く発揮できなかった");
    } else if (scoreSelfLearning < 29) {
      setWordSelfLearning("あまり発揮できなかった");
    } else if (scoreSelfLearning < 43) {
      setWordSelfLearning("少し発揮できなかった");
    } else if (scoreSelfLearning < 57) {
      setWordSelfLearning("ふつう");
    } else if (scoreSelfLearning < 71) {
      setWordSelfLearning("少しは発揮した");
    } else if (scoreSelfLearning < 85) {
      setWordSelfLearning("まあまあ発揮した");
    } else {
      setWordSelfLearning("大いに発揮した");
    }
  }, [scoreSelfLearning]);

  // ポートフォリオを保存するときに必要な情報が全て記入されているかのチェック
  const checkSaveInfomation = (): boolean => {
    if (
      title.length > 0 &&
      videoQuery.dateFrom &&
      videoQuery.dateTo &&
      videoQuery.category.length > 0 &&
      !isSaving &&
      isChanged
    ) {
      return true;
    } else {
      return false;
    }
  };

  //遷移ボタンの処理
  const moveUrl = (url: string): void => {
    history.push(url);
  };

  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleTitleInput = (
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    setTitle(e.currentTarget.value);
    setIsChanged(true);
  };
  const handleReviewInput = (
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    setReview(e.currentTarget.value);
    setIsChanged(true);
  };
  const handleScoreCL = (event: any, newValue: number | number[]) => {
    setScoreContinueLearning(newValue as number);
    setIsChanged(true);
  };
  const handleScoreSL = (event: any, newValue: number | number[]) => {
    setScoreSelfLearning(newValue as number);
    setIsChanged(true);
  };

  // 保存ボタンを押した時の動作
  const handleSavePortfolio = async () => {
    try {
      setIsSaving(true);
      if (isUpdate) {
        // 更新の場合
        await savePortfolio(
          review,
          scoreContinueLearning,
          scoreSelfLearning,
          isUpdate,
          undefined,
          videoQuery.dateFrom,
          videoQuery.dateTo,
          undefined,
          portfolioId
        );
      } else {
        // 初回の保存の場合(作成で連続でボタン押す場合も含む)
        const latestPortfolioId = await savePortfolio(
          review,
          scoreContinueLearning,
          scoreSelfLearning,
          isUpdate,
          title,
          videoQuery.dateFrom,
          videoQuery.dateTo,
          videoQuery.category,
          portfolioId
        );
        setPortfolioId(latestPortfolioId);
      }
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "ポートフォリオを保存しました。",
        type: "success",
        autoHideDuration: 3000,
      });
      setIsChanged(false);
    } catch (e) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "ポートフォリオの保存が出来ませんでした。時間をおいて再度お試しください。",
        type: "error",
        autoHideDuration: null,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditModeButton = (e: any) => {
    if (userId === "") {
      setIsEditMode(!isEditMode);
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.pageTitle}>
          <Typography variant="h5" className={classes.textGrey}>
            ポートフォリオ
          </Typography>
        </div>
        <Grid container justify="flex-end" alignItems="flex-end">
          <Grid item>
            <IconButton
              color={isEditMode ? "default" : "secondary"}
              disabled={!isUpdate || userId !== ""}
              onClick={handleEditModeButton}
            >
              <EditTwoToneIcon />
              <Typography>編集</Typography>
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.mainContent}>
          <Grid item xs={4}>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="h6" className={classes.textGrey}>
                  単元名
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  size="small"
                  value={title}
                  onChange={handleTitleInput}
                  inputProps={{
                    maxLength: max_title_length,
                  }}
                  disabled={isUpdate}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="h6" className={classes.textGrey}>
                  期間
                </Typography>
              </Grid>
              <Grid item>
                <DatePicker
                  locale="ja-JP"
                  formatDay={(locale, date) => `${date.getDate()}`}
                  maxDate={new Date(videoQuery.dateTo.getFullYear(), 
                    videoQuery.dateTo.getMonth(), 
                    videoQuery.dateTo.getDate(), 23, 59, 59)}
                  onChange={(value: Date) => {
                    setVideoQuery({ ...videoQuery, dateFrom: value });
                  }}
                  value={videoQuery.dateFrom}
                  disabled={isUpdate && !isEditMode}
                />
              </Grid>
              <Typography>〜</Typography>
              <Grid item>
                <DatePicker
                  locale="ja-JP"
                  formatDay={(locale, date) => `${date.getDate()}`}
                  minDate={videoQuery.dateFrom}
                  onChange={(value: Date) => {
                    setVideoQuery({ ...videoQuery, dateTo: value });
                  }}
                  value={videoQuery.dateTo}
                  disabled={isUpdate && !isEditMode}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container justify="flex-end" alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h6" className={classes.textGrey}>
                  学習内容
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  size="small"
                  disabled
                  value={videoQuery.category}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      disabled: classes.disabled,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCategoryClick}
                  disabled={isUpdate}
                >
                  選択
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={6}>
                <div className={classes.centering}>
                  <Typography className={classes.textGrey}>
                    {`視聴動画: ${reviewHistory.length}本`}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.centering}>
                  <Typography className={classes.textGrey}>
                    {`視聴時間: ${viewTime}分`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Paper className={classes.scrollable} variant="outlined" square>
              {reviewHistory.map((value, index) => {
                return (
                  <VideoCardDetail
                    {...value}
                    key={index}
                    backgroundColor="white"
                  />
                );
              })}
              {reviewHistory.length === 0 ? (
                <div className={classes.centering}>
                  <Typography
                    variant="h6"
                    className={classes.textGrey}
                    style={{ marginTop: "1rem" }}
                  >
                    動画がありません
                  </Typography>
                </div>
              ) : (
                <></>
              )}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.centering}>
              <Typography variant="h6" className={classes.textGrey}>
                全体を振り返って
              </Typography>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              value={review}
              onChange={handleReviewInput}
              multiline
              rows={10}
              className={classes.reviewInput}
              inputProps={{
                maxLength: max_review_length,
              }}
              helperText={`${review.length}文字`}
              disabled={isUpdate && !isEditMode}
            />
            <div className={classes.reviewInput} >
                <Typography
                  className={classes.textGrey}
                >{`「粘り強さ」: ${wordContinueLearning}`}</Typography>
            </div>
              <Tooltip
                classes={{tooltip: classes.customTooltipContinueLearning}}
                title=
                  {
                    <>
                      <div className={classes.tooltipTitle}>
                        <ruby>粘<rt>ねば</rt></ruby>り<ruby>強<rt>づよ</rt></ruby>く学習に取り組む<ruby>態<rt>たい</rt>度<rt>ど</rt></ruby>
                      </div>
                      <br></br>
                      <div className={classes.tooltipTitle}>
                        学びの<ruby>自<rt>じ</rt>己<rt>こ</rt>修<rt>しゅう</rt>正<rt>せい</rt></ruby>／<ruby>改<rt>かい</rt>善<rt>ぜん</rt></ruby>への取組の様子
                      </div>
                      <br></br>
                      <br></br>
                      <div style={{marginBottom: "8px"}} className={classes.tooltipText}>
                        問題にあきらめないで取り組んでいる
                      </div>
                      <br></br>
                      <div className={classes.tooltipText}>
                        作品をより良いものに仕上げようとしている
                      </div>
                      <br></br>
                      <div className={classes.tooltipText}>
                        解決に向けて<ruby>最<rt>さい</rt>適<rt>てき</rt>解<rt>かい</rt></ruby>を探し出そうとしている
                      </div>
                      <br></br>
                      <div className={classes.tooltipText}>
                        考えを<ruby>練<rt>ね</rt></ruby>り<ruby>直<rt>なお</rt></ruby>している
                      </div>
                      <br></br>
                      <div className={classes.tooltipText}>
                        文章の<ruby>修<rt>しゅう</rt>正<rt>せい</rt></ruby>や<ruby>訂<rt>てい</rt>正<rt>せい</rt></ruby>をしている
                      </div>
                      <br></br>
                      <div className={classes.tooltipText}>
                        集中して<ruby>課<rt>か</rt>題<rt>だい</rt></ruby>に取り組んでいる
                      </div>
                      <br></br>
                      <div style={{marginTop: "8px"}} className={classes.tooltipText}>
                        考えや思いなどをわかりやすく表現しようとしている
                      </div>
                      <br></br>
                    </>
                  }
                placement='top'
                arrow>
                <Slider
                  value={scoreContinueLearning}
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={100}
                  onChange={handleScoreCL}
                  disabled={isUpdate && !isEditMode}
                />
              </Tooltip>  
            <div className={classes.reviewInput}>
              <Typography
                className={classes.textGrey}
              >{`「自己調整」: ${wordSelfLearning}`}</Typography>
            </div>
              <Tooltip
                classes={{tooltip: classes.customTooltipSelfLearning}}
                  title=
                    {
                      <>
                        <div className={classes.tooltipTitle}>
                          自ら学習を<ruby>調<rt>ちょう</rt>整<rt>せい</rt></ruby>しようとする<ruby>態<rt>たい</rt>度<rt>ど</rt></ruby>
                        </div>
                        <br></br>
                        <div className={classes.tooltipTitle}>
                          <ruby>自<rt>じ</rt>己<rt>こ</rt>調<rt>ちょう</rt>整<rt>せい</rt>力<rt>りょく</rt></ruby>の3つの<ruby>要<rt>よう</rt>素<rt>そ</rt></ruby>
                        </div>
                        <br></br>
                        <div className={classes.tooltipText}>
                          【メタ<ruby>認<rt>にん</rt>知<rt>ち</rt></ruby>】
                        </div>
                        <br></br>
                        <div className={classes.tooltipText}>
                          &nbsp;&nbsp;&nbsp;&nbsp;振り返りをしっかりと行っている
                        </div>
                        <br></br>
                        <div className={classes.tooltipText}>
                          【学習<ruby>方<rt>ほう</rt>略<rt>りゃく</rt></ruby>】
                        </div>
                        <br></br>
                        <div className={classes.tooltipText}>
                          &nbsp;&nbsp;&nbsp;&nbsp;様々な気付きを得ることができた
                        </div>
                        <br></br>
                        <div className={classes.tooltipText}>
                          【<ruby>動<rt>どう</rt>機<rt>き</rt>付<rt>づ</rt></ruby>け】
                        </div>
                        <br></br>
                        <div className={classes.tooltipText}>
                          &nbsp;&nbsp;&nbsp;&nbsp;ワクワク／ドキドキしながら
                        </div>
                        <br></br>
                        <div className={classes.tooltipText}>
                          &nbsp;&nbsp;&nbsp;&nbsp;学びを進めようとしている
                        </div>
                        <br></br>
                      </>
                    }
                placement='top'
                arrow>
                <Slider
                  value={scoreSelfLearning}
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={100}
                  onChange={handleScoreSL}
                  disabled={isUpdate && !isEditMode}
                />
              </Tooltip>
            <div className={classes.saveButton}>
              <Button
                variant="contained"
                color="primary"
                disabled={!checkSaveInfomation() || (isUpdate && !isEditMode)}
                onClick={handleSavePortfolio}
              >
                　保存　
              </Button>
            </div>
            <div className={classes.saveButton}>
              <Grid container>
                <Grid item xs={6}>
                  <div className={classes.centering}>
                    <Link
                      className={classes.movePage}
                      onClick={() =>
                        moveUrl(
                          "/portfolio-list" + (userId ? "?id=" + userId : "")
                        )
                      }
                    >
                      ポートフォリオ一覧
                    </Link>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.centering}>
                    <Link
                      className={classes.movePage}
                      onClick={() => moveUrl("/mypage")}
                    >
                      マイページ
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <CategoryNestedPulldown
          anchor={anchor}
          setAnchor={setAnchor}
          setCategory={(value) => {
            setVideoQuery({ ...videoQuery, category: value });
          }}
          left
          addOthers
        />
      </Container>
      <FeedbackBar {...feedbackInfo} handleClose={handleClose} />
    </>
  );
};

export default PortfolioPage;
