import React, { FC, useContext, useEffect, useState } from "react";
import { Typography, Grid, Container, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineRobot } from "react-icons/ai";
import { useHistory } from "react-router-dom";

// self-modules
import types from "../store/types";
import theme_app from "../Theme";
import { Store } from "../store";
import VideoFeedbackCard, {
  VideoFeedbackProps,
} from "../components/common/videoFeedbackCard";
import VideoCard, { VideoCardProps } from "../components/common/videoCard";
import { getPublicReviews, getHighScoreVideos } from "../utils/http-requests";
import DatetimeParse from "../components/common/datetime";
import FooterContent from "../components/footer";

const useStyles = makeStyles({
  centering: {
    textAlign: "center",
  },
  gridContainer: {
    marginTop: theme_app.spacing(4),
  },
  recommendButton: {
    fontSize: theme_app.spacing(2.5),
  },
  textGrey: {
    color: "#616161",
  },
});

const MainPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);
  const [highScoreVideos, setHighScoreVideos] = useState(
    Array<VideoCardProps>()
  );
  const [reviews, setReviews] = useState(Array<VideoFeedbackProps>());

  useEffect(() => {
    const f = async () => {
      const dataReview = await getPublicReviews();
      setReviews(dataReview);
      const dataVideo = await getHighScoreVideos();
      setHighScoreVideos(dataVideo);
    };
    f();
  }, []);

  useEffect(() => {
    // 最終ユーザ情報更新から１ヶ月以上経過しているのであれば，属性情報更新へ移動
    if (state.lastUserInfoUpdateTime !== null) {
      let now = new Date();
      const diff =
        (now.getTime() - state.lastUserInfoUpdateTime.getTime()) / 86400000;
      if (diff > 30) {
        history.push("/settings");
      }
    }
  }, [state.lastUserInfoUpdateTime]);

  const changeRoute = (url: string) => {
    history.push(url);
  };

  return (
    <>
      {state.lastRecommendTime.length === 0 ? (
        <Typography>　</Typography>
      ) : (
        <></>
      )}
      <DatetimeParse
        dateString={
          state.lastRecommendTime.length > 0 ? state.lastRecommendTime[0] : null
        }
        index={null}
      />
      <Container maxWidth="lg">
        <Grid container justify="center">
          <Grid>
            <Button
              variant="contained"
              color="primary"
              className={classes.recommendButton}
              onClick={() => {
                changeRoute("/recommend");
              }}
              startIcon={<AiOutlineRobot size={theme_app.spacing(3.5)} />}
            >
              授業の感想からレコメンド
            </Button>
          </Grid>
        </Grid>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper elevation={0}>
              <div className={classes.centering}>
                <Typography variant="h6" className={classes.textGrey}>
                  おすすめの動画
                </Typography>
              </div>
              {highScoreVideos.map((value, index) => {
                return <VideoCard {...value} key={index} />;
              })}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper elevation={0}>
              <div className={classes.centering}>
                <Typography variant="h6" className={classes.textGrey}>
                  他の人のおすすめ
                </Typography>
              </div>
              {reviews.map((value, index) => {
                return <VideoFeedbackCard {...value} key={index} />;
              })}
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <FooterContent />
    </>
  );
};

export default MainPage;
