import React, { FC } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Appbar from "./components/appbar";
import Sidebar from "./components/sidebar";
import theme_app from "./Theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import StateProvider from "./store";

// import pages
import MainPage from "./pages/main";
import MyPage from "./pages/my-page";
import LoginPage from "./pages/login";
import RecommendPage from "./pages/recommend";
import GroupPage from "./pages/group";
import WatchVideoPage from "./pages/watch-video";
import RegisterPage from "./pages/register";
import MailRegisterPage from "./pages/mail-register";
import ResetPasswordPage from "./pages/reset-password";
import SettingsPage from "./pages/settings";
import TermofServicePage from "./pages/termofservice";
import PrivacyPolicyPage from "./pages/privacy-policy";
import PortfolioListPage from "./pages/portfolio-list";
import PortfolioProfilePage from "./pages/portfolio-profile";
import PortfolioPage from "./pages/portfolio";
import ManageTablePage from "./pages/manage-students";

// import utils
import { Store } from "./store";
import Auth, { AuthCheck } from "./components/common/auth";

const App: FC<{}> = (props) => {
  const theme = theme_app;

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <StateProvider>
            <Appbar />
            <Sidebar />
            <AuthCheck />
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/mail-register" component={MailRegisterPage} />
              <Route exact path="/register" component={RegisterPage} />
              <Route
                exact
                path="/reset-password"
                component={ResetPasswordPage}
              />
              <Route
                exact
                path="/term_of_service"
                component={TermofServicePage}
              />
              <Route
                exact
                path="/privacy_policy"
                component={PrivacyPolicyPage}
              />
              <Auth>
                <Switch>
                  <Route exact path="/main" component={MainPage} />
                  <Route exact path="/mypage" component={MyPage} />
                  <Route exact path="/recommend" component={RecommendPage} />
                  <Route exact path="/group" component={GroupPage} />
                  <Route exact path="/settings" component={SettingsPage} />
                  <Route
                    exact
                    path="/portfolio-list"
                    component={PortfolioListPage}
                  />
                  <Route
                    exact
                    path="/manage-students"
                    component={ManageTablePage}
                  />
                  <Route path="/portfolio" component={PortfolioPage} />
                  <Route path="/portfolio-profile" component={PortfolioProfilePage}/>
                  <Route path="/video" component={WatchVideoPage} />
                  <Route component={() => <Redirect to="/main" />} />
                </Switch>
              </Auth>
              <Route component={() => <Redirect to="/login" />} />
            </Switch>
          </StateProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </>
  );
};

export default App;
