// ポートフォリオ一覧ページのグラフ
import React, { FC, useEffect, useState } from "react";
import {
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Scatter,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Typography, Paper, Grid, Box } from "@material-ui/core";
import PortfolioCardList, {
  PortfolioCardListProps,
  PortfolioCardProps,
} from "./portfolio-card";

interface categoryData {
  mathematics: Array<scatterData>;
  science: Array<scatterData>;
  programming: Array<scatterData>;
  technology: Array<scatterData>;
  informatics: Array<scatterData>;
  english: Array<scatterData>;
  others: Array<scatterData>;
}

interface scatterData {
  title: string;
  scoreContinueLearning: number;
  scoreSelfLearning: number;
}

// 小分類カテゴリ名を大分類カテゴリ名へ変換する関数
export const categoryParser = (category: string): string => {
  if (category.indexOf("算数") > -1 || category.indexOf("数学") > -1) {
    return "算数/数学";
  } else if (
    category.indexOf("理科") > -1 ||
    category.indexOf("物理") > -1 ||
    category.indexOf("化学") > -1 ||
    category.indexOf("高校/生物") > -1 ||
    category.indexOf("地学") > -1
  ) {
    return "理科";
  } else if (
    category.indexOf("情報Ⅰ") > -1 ||
    category.indexOf("情報Ⅱ") > -1 ||
    category == "情報"
  ) {
    return "情報（高）";
  } else if (
    category.indexOf("プログラミング") > -1 ||
    category == "ロボット" || // v3.1.2(2021/12/6)までの授業内容（名称変更）
    category == "センサー" || // 2022/10上旬までの授業内容（名称変更）
    category == "双方向" || // 2022/10上旬までの授業内容
    category == "計測制御" // 2022/10上旬までの授業内容
  ) {
    return "プログラミング";
  } else if (
    category.indexOf("技術/") > -1 ||
    category == "材料" || // 2022/10上旬までの授業内容（名称変更）
    category == "エネルギー" // 2022/10上旬までの授業内容（名称変更）
  ) {
    return "技術（中）";
  } else if (
    category.indexOf("英語") > -1
  ) {
    return "英語/外国語";
  } else {
    return "その他";
  }
};

const graphDataParser = (data: PortfolioCardProps): scatterData => {
  const result: scatterData = {
    title: data.title,
    scoreContinueLearning: data.scoreContinueLearning,
    scoreSelfLearning: data.scoreSelfLearning,
  };
  return result;
};

const CustomTooltip: FC<any> = ({ active, payload }) => {
  if (!active) {
    return null;
  } else {
    const data: scatterData = payload[0].payload;
    return (
      <>
        <Paper style={{ padding: "1rem" }}>
          <Grid container>
            <Grid item>
              <Typography style={{ color: "#616161" }}>{"単元名："}</Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: "#26ad77" }}>
                <Box fontWeight="fontWeightBold">{data.title}</Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography style={{ color: "#616161" }}>
                {"粘り強く学習に取り組む態度："}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: "#26ad77" }}>
                <Box fontWeight="fontWeightBold">
                  {data.scoreContinueLearning}
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography style={{ color: "#616161" }}>
                {"自ら学習を調整しようとする態度："}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: "#26ad77" }}>
                <Box fontWeight="fontWeightBold">{data.scoreSelfLearning}</Box>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
  return null;
};

const portfolioDataProcesser = (
  props: Array<PortfolioCardProps>
): categoryData => {
  const result = {
    mathematics: [],
    science: [],
    programming: [],
    technology: [],
    informatics: [],
    english: [],
    others: [],
  } as categoryData;
  for (let i = 0; i < props.length; i++) {
    switch (categoryParser(props[i].category)) {
      case "算数/数学":
        result.mathematics.push(graphDataParser(props[i]));
        break;
      case "理科":
        result.science.push(graphDataParser(props[i]));
        break;
      case "プログラミング":
        result.programming.push(graphDataParser(props[i]));
        break;
      case "技術（中）":
        result.technology.push(graphDataParser(props[i]));
        break;
      case "情報（高）":
        result.informatics.push(graphDataParser(props[i]));
        break;
      case "英語/外国語":
        result.english.push(graphDataParser(props[i]));
        break;
      default:
        result.others.push(graphDataParser(props[i]));
    }
  }
  return result;
};

const PortfolioGraph: FC<PortfolioCardListProps> = (props) => {
  const [isloading, setIsloading] = useState(true);
  const [portfolioData, setPortfolioData] = useState({
    mathematics: [],
    science: [],
    programming: [],
    technology: [],
    informatics: [],
    english: [],
    others: [],
  } as categoryData);

  const [isLabelDisplayed, setIsLabelDisplayed] = useState({
    mathematics: true,
    science: true,
    programming: true,
    technology: true,
    informatics: true,
    english: true,
    others: true,
  })

  const handleMouseEnter = (m: any) => {
    const dataField = m.value
    switch (dataField) {
      case "算数/数学":
        setIsLabelDisplayed({
          ...isLabelDisplayed,
          science: false,
          programming: false,
          technology: false,
          informatics: false,
          english: false,
          others: false,
        })
        break;
      case "理科":
        setIsLabelDisplayed({
          ...isLabelDisplayed,
          mathematics: false,
          programming: false,
          technology: false,
          informatics: false,
          english: false,
          others: false
        })
        break;
      case "プログラミング":
        setIsLabelDisplayed({
          ...isLabelDisplayed,
          mathematics: false,
          science: false,
          technology: false,
          informatics: false,
          english: false,
          others: false
        })
        break;
      case "技術（中）":
        setIsLabelDisplayed({
          ...isLabelDisplayed,
          mathematics: false,
          science: false,
          programming: false,
          informatics: false,
          english: false,
          others: false
        })
        break;
      case "情報（高）":
        setIsLabelDisplayed({
          ...isLabelDisplayed,
          mathematics: false,
          science: false,
          programming: false,
          technology: false,
          english: false,
          others: false
        })
        break;
      case "英語/外国語":
        setIsLabelDisplayed({
          ...isLabelDisplayed,
          mathematics: false,
          science: false,
          programming: false,
          technology: false,
          informatics: false,
          others: false
        })
        break;
      case "その他":
        setIsLabelDisplayed({
          ...isLabelDisplayed,
          mathematics: false,
          science: false,
          programming: false,
          technology: false,
          informatics: false,
          english: false,
        })
    }
  }

  const handleMouseLeave = (m: any) => {
    setIsLabelDisplayed({
      mathematics: true,
      science: true,
      programming: true,
      technology: true,
      informatics: true,
      english: true,
      others: true,
    })
  }


  useEffect(() => {
    const data: categoryData = portfolioDataProcesser(props.data);
    setPortfolioData(data);
  }, [props.data]);

  return (
    <ResponsiveContainer width="100%" height="100%" maxHeight={600}>
      <ScatterChart margin={{ top: 70, right: 100 }}>
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="scoreContinueLearning"
          name="粘り強く学習に取り組む態度"
          label={{
            value: "粘り強く学習に取り組む態度",
            dy: 20,
          }}
          domain={[0, 100]}
        />
        <YAxis
          type="number"
          dataKey="scoreSelfLearning"
          name="自ら学習を調整しようとする態度"
          label={{
            value: "自ら学習を調整しようとする態度",
            angle: -90,
            dx: -15,
          }}
          domain={[0, 100]}
        />
        <ZAxis range={[100, 100]} />
        <Tooltip cursor={{ strokeDasharray: "3 3" }} content={CustomTooltip} />
        {
          (portfolioData.mathematics.length > 0) ? (
            <Scatter
              name="算数/数学"
              data={(isLabelDisplayed.mathematics) ? portfolioData.mathematics : undefined}
              fill="#42c5f5"
              shape="square"
              legendType="square"
            />
          ) : (
            <></>
          )}
        {
          (portfolioData.science.length > 0) ? (
            <Scatter
              name="理科"
              data={(isLabelDisplayed.science) ? portfolioData.science : undefined}
              fill="#2ec955"
              shape="circle"
              legendType="circle"
            />
          ) : (
            <></>
          )}
        {
          (portfolioData.programming.length > 0) ? (
            <Scatter
              name="プログラミング"
              data={(isLabelDisplayed.programming) ? portfolioData.programming : undefined}
              fill="#b2b500"
              shape="star"
              legendType="star"
            />
          ) : (
            <></>
          )}
        {
          (portfolioData.technology.length > 0) ? (
            <Scatter
              name="技術（中）"
              data={(isLabelDisplayed.technology) ? portfolioData.technology : undefined}
              fill="#e0a500"
              shape="diamond"
              legendType="diamond"
            />
          ) : (
            <></>
          )}
        {
          (portfolioData.informatics.length > 0) ? (
            <Scatter
              name="情報（高）"
              data={(isLabelDisplayed.informatics) ? portfolioData.informatics : undefined}
              fill="#e06100"
              shape="triangle"
              legendType="triangle"
            />
          ) : (
            <></>
          )}
        {
          (portfolioData.english.length > 0) ? (
            <Scatter
              name="英語/外国語"
              data={(isLabelDisplayed.english) ? portfolioData.english : undefined}
              fill="#ff9393"
              shape="wye"
              legendType="wye"
            />
          ) : (
            <></>
          )}
        {
          (portfolioData.others.length > 0) ? (
            <Scatter
              name="その他"
              data={(isLabelDisplayed.others) ? portfolioData.others : undefined}
              fill="#8884d8"
              shape="cross"
              legendType="cross"
            />
          ) : (
            <></>
          )}
        <Legend
          wrapperStyle={{
            paddingTop: "2em",
          }}
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default PortfolioGraph;