import React, { FC, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import List from "@material-ui/core/List";
import {
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import HistoryIcon from "@material-ui/icons/History";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { useHistory } from "react-router-dom";

// self-modules
import { Store } from "../store";
import types from "../store/types";
import { auth } from "../utils/firebase";

const Sidebar: FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(Store);
  const handleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      closeDrawer();
    };
  const closeDrawer = () => {
    dispatch({ type: types.SIDEBAR_CLICK });
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      dispatch({ type: types.SIDEBAR_CLICK });
      dispatch({
        type: types.SET_SIGNIN_STATE,
        payload: {
          signInState: "",
        },
      });
    } catch (e) {
      alert(e);
    }
  };
  const changeRoute = (url: string) => {
    dispatch({ type: types.SIDEBAR_CLICK });
    history.push(url);
  };

  const list = () => (
    <>
      <List>
        {state.signInState === "SignIn" ? (
          <>
            <ListItem button key="main" onClick={() => changeRoute("/main")}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="メインページ" />
            </ListItem>
            <ListItem
              button
              key="recommend"
              onClick={() => changeRoute("/recommend")}
            >
              <ListItemIcon>
                <RecentActorsIcon />
              </ListItemIcon>
              <ListItemText primary="レコメンド" />
            </ListItem>
            <ListItem
              button
              key="mypage"
              onClick={() => changeRoute("/mypage")}
            >
              <ListItemIcon>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText primary="マイページ" />
            </ListItem>
            <ListItem
              button
              key="portfolio-list"
              onClick={() => changeRoute("/portfolio-list")}
            >
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary="ポートフォリオ" />
            </ListItem>
          </>
        ) : (
          <></>
        )}
        {state.signInState === "SignIn" && state.accountType === "teacher" ? (
          <>
            <ListItem
              button
              key="manage-students"
              onClick={() => changeRoute("/manage-students")}
            >
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary="児童・生徒管理画面" />
            </ListItem>
            <ListItem button onClick={() => changeRoute("/group")}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="グループ化" />
            </ListItem>
          </>
        ) : (
          <></>
        )}
        {state.signInState === "SignIn" ? (
          <ListItem button onClick={() => changeRoute("/settings")}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="設定" />
          </ListItem>
        ) : (
          <></>
        )}
        {state.signInState === "SignIn" ||
        state.signInState === "EmailVerified" ? (
          <ListItem button key="signout" onClick={handleSignOut}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="ログアウト" />
          </ListItem>
        ) : (
          <></>
        )}
      </List>
    </>
  );

  return (
    <div>
      <Drawer anchor="left" open={state.sidebarState} onClose={handleDrawer()}>
        {list()}
      </Drawer>
    </div>
  );
};

export default Sidebar;
