import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { Typography, Card, Grid, ButtonBase, Link } from "@material-ui/core";
import theme_app from "../../Theme";

export interface VideoCardProps {
  title: string;
  channelTitle: string;
  videoId: string;
  backgroundColor?: string;
  isRecommended?: boolean;
  defaultThumbnail?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    cardstyle: {
      backgroundColor: grey[50],
    },
    cardstyleWhite: {
      backgroundColor: "ffffff",
    },
    cardstyleMargin: {
      margin: theme_app.spacing(4),
    },
    cardstyleMarginDefaultThumbnail: {
      margin: theme_app.spacing(1),
    },
    vid_title: {
      cursor: "pointer",
      marginTop: theme.spacing(2),
    },
    centering: {
      textAlign: "center",
    },
  })
);

const VideoCard: FC<VideoCardProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const youtubeURL = () => {
    history.push(
      `/video?v=${props.videoId}&type=${
        props.isRecommended ? "recommend" : "other"
      }`
    );
  };
  const [imageUrl, setImageUrl] = useState(
    props.defaultThumbnail
      ? `https://i.ytimg.com/vi/${props.videoId}/default.jpg`
      : `https://i.ytimg.com/vi/${props.videoId}/maxresdefault.jpg`
  );
  const img = new Image();
  img.src = props.defaultThumbnail
    ? `https://i.ytimg.com/vi/${props.videoId}/default.jpg`
    : `https://i.ytimg.com/vi/${props.videoId}/maxresdefault.jpg`;
  img.onload = (e: any) => {
    if (img.width === 120 && img.height === 90) {
      setImageUrl(props.defaultThumbnail
        ? `https://i.ytimg.com/vi/${props.videoId}/default.jpg`
        : `https://i.ytimg.com/vi/${props.videoId}/mqdefault.jpg`);
    } else {
      setImageUrl(`https://i.ytimg.com/vi/${props.videoId}/maxresdefault.jpg`);
    }
  };

  return (
    <Card
      variant="outlined"
      className={
        (props.backgroundColor ? classes.cardstyleWhite : classes.cardstyle)
        + " "
        + (props.defaultThumbnail ? classes.cardstyleMarginDefaultThumbnail : classes.cardstyleMargin)
      }
    >
      <Grid container alignItems="center" justify="center">
        <Grid item xs={8} sm={5}>
          <ButtonBase className={classes.image} onClick={youtubeURL}>
            <img className={classes.image} alt="complex" src={imageUrl} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm={7}>
          <div className={classes.centering}>
            <Typography className={classes.vid_title} gutterBottom variant={props.defaultThumbnail ? "inherit" : "h6"}>
              <Link onClick={youtubeURL} color="inherit">
                {props.title}
              </Link>
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              {props.channelTitle}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default VideoCard;
