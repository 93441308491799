import { CallToActionSharp } from "@material-ui/icons";
import React from "react";
import types, { State, Actions } from "./types";

export default function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case types.SIDEBAR_CLICK:
      return { ...state, sidebarState: !state.sidebarState };
    case types.SET_USER_FIREBASE:
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        emailVerified: action.payload.emailVerified,
        uid: action.payload.uid,
        accountType: action.payload.accountType,
      };
    case types.SET_SIGNIN_STATE:
      return {
        ...state,
        signInState: action.payload.signInState,
      };
    case types.SET_LAST_TIME:
      return {
        ...state,
        lastRecommendTime: action.payload.lastRecommendTime,
        lastUserInfoUpdateTime: action.payload.lastUserInfoUpdateTime,
      };
    case types.SET_RECOMMEND_DATA:
      return {
        ...state,
        recommendReview: action.payload.recommendReview,
        recommendCategory: action.payload.recommendCategory,
        recommendWeight: action.payload.recommendWeight,
      };
    case types.SET_RECOMMEND_RESULT:
      return {
        ...state,
        recommendResult: action.payload.recommendResult,
      };
    case types.SET_USER_NICKNAME:
      return {
        ...state,
        name: action.payload.name,
      };
    case types.CLEAR_LAST_USER_INFO_UPDATE_TIME:
      return {
        ...state,
        lastUserInfoUpdateTime: null,
      };
    default:
      throw new Error("Unexpected Action Type");
  }
}
