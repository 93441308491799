import * as actions from "./actions";
import { VideoCardProps } from "../components/common/videoCard";

export default {
  SIDEBAR_CLICK: "SIDEBAR_CLICK",
  SET_USER_FIREBASE: "SET_USER_FIREBASE",
  SET_SIGNIN_STATE: "SET_SIGNIN_STATE",
  SET_LAST_TIME: "SET_LAST_TIME",
  SET_RECOMMEND_DATA: "SET_RECOMMEND_DATA",
  SET_RECOMMEND_RESULT: "SET_RECOMMEND_RESULT",
  SET_USER_NICKNAME: "SET_USER_NICKNAME",
  CLEAR_LAST_USER_INFO_UPDATE_TIME: "CLEAR_LAST_USER_INFO_UPDATE_TIME",
} as const;

export type State = {
  name: string;
  nickname: string;
  email: string;
  emailVerified: boolean;
  uid: string;
  sidebarState: boolean;
  signInState: "SignIn" | "EmailVerified" | "NotSignIn" | "";
  accountType: "teacher" | "student" | "";
  lastRecommendTime: Array<Date>;
  lastUserInfoUpdateTime: Date | null;
  recommendReview: string;
  recommendCategory: string;
  recommendWeight: number;
  recommendResult: Array<VideoCardProps>;
};

type ReturnTypes<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any
    ? ReturnType<T[K]>
    : never;
};
type Unbox<T> = T extends { [K in keyof T]: infer U } ? U : never;
type CreatorsToActions<T> = Unbox<ReturnTypes<T>>;
export type Actions = CreatorsToActions<typeof actions>;
