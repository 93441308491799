import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Paper,
  Select,
  MenuItem,
  Button,
  Tooltip,
  Collapse,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { red } from "@material-ui/core/colors";

// self modules
import { auth } from "../utils/firebase";
import { Store } from "../store";
import types from "../store/types";
import theme_app from "../Theme";
import { FeedbackBar } from "../utils/feedback";
import {
  enableStudentToken,
  disableStudentToken,
  getStudentToken,
  getUserData,
  updateUserData,
  addTeacher,
} from "../utils/http-requests";

const useStyles = makeStyles({
  register_paper: {
    marginTop: theme_app.spacing(2),
    marginBottom: theme_app.spacing(5),
    backgroundColor: "#F4F5F7",
  },
  categoryText: {
    textAlign: "center",
    paddingTop: theme_app.spacing(2),
  },
  cardTitle: {
    textAlign: "center",
    paddingTop: theme_app.spacing(2),
    display: "flex",
  },
  helperIcon: {
    fontSize: theme_app.spacing(3),
  },
  helperText: {
    padding: "0.5rem",
    fontSize: theme_app.spacing(2),
  },
  bg_color: {
    backgroundColor: "darkgray",
  },
  tokenInput: {
    paddingTop: theme_app.spacing(1),
  },
  tokenSubmitButton: {
    marginTop: theme_app.spacing(2),
    marginBottom: theme_app.spacing(2),
  },
  register_item: {
    marginTop: theme_app.spacing(2),
  },
  textGrey: {
    color: "#616161",
  },
  centerGrey: {
    color: "#616161",
    textAlign: "center",
    paddingBottom: theme_app.spacing(1),
  },
  outdateInfo: {
    padding: theme_app.spacing(2),
    borderColor: red[300],
    borderWidth: "3px",
    height: "100%",
    width: "100%",
  },
});

type FormType = {
  name: string;
  value: string;
};

const SettingsPage: FC = () => {
  const classes = useStyles();
  const history = useHistory()
  const { state, dispatch } = useContext(Store);
  const [isOutDated, setIsOutDated] = useState(false);
  const [tokenData, setTokenData] = useState({
    token: "",
    valid: true,
    helper: "",
    isEnable: false,
    isSending: false,
    isChecking: true,
  });
  const [userTokenData, setUserTokenData] = useState({
    token: "",
    valid: true,
    helper: "",
    isSending: false,
  });
  const [userData, setUserData] = useState({
    nickname: "",
    nicknameValid: true,
    nicknameHelper: "",
    stemInterest: -1,
    progInterest: -1,
    updated: false,
    isSending: false,
  });
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  useEffect(() => {
    // 最終ユーザ情報更新日の取得
    if (state.lastUserInfoUpdateTime !== null) {
      let now = new Date();
      const diff =
        (now.getTime() - state.lastUserInfoUpdateTime.getTime()) / 86400000;
      if (diff > 30) {
        // 最終ユーザ情報更新から１ヶ月以上経過しているのであれば，属性情報更新へ移動
        setIsOutDated(true);
      } else {
        setIsOutDated(false);
      }
    }
  }, [state.lastUserInfoUpdateTime]);

  const handleTokenInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // トークンの入力制御の関数
    e.preventDefault();
    const token = e.target.value;
    const valid = token.match(/^\d\d\d\d\d\d$/) ? true : false;
    const helper = valid ? "" : "トークンは6桁の数字を設定してください";
    setTokenData({ ...tokenData, token, valid, helper });
  };

  const handleUserTokenInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // トークン(生徒が先生を追加する用)の入力制御の関数
    e.preventDefault();
    const token = e.target.value;
    const valid = token.match(/^\d\d\d\d\d\d$/) ? true : false;
    const helper = valid ? "" : "トークンは6桁の数字を設定してください";
    setUserTokenData({ ...userTokenData, token, valid, helper });
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  const handleTokenSubmit = async () => {
    // トークン登録ボタンの処理
    setTokenData({ ...tokenData, isSending: true });
    try {
      await enableStudentToken(tokenData.token);
      setTokenData({ ...tokenData, isChecking: true });
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: `生徒用トークン【${tokenData.token}】を発行いたしました。`,
        type: "success",
      });
    } catch (error) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "生徒用トークンを発行できませんでした。トークンの値を変えるか、時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setTokenData({ ...tokenData, isSending: false });
    }
  };

  const handleUserTokenSubmit = async () => {
    // トークン登録ボタン(生徒用)の処理
    setUserTokenData({ ...userTokenData, isSending: true });
    try {
      const status = await addTeacher(userTokenData.token);
      if (status === 200) {
        setFeedbackInfo({
          ...feedbackInfo,
          open: true,
          text: `生徒用トークン【${userTokenData.token}】で先生を追加しました`,
          type: "success",
        });
      } else if (status === 201) {
        setFeedbackInfo({
          ...feedbackInfo,
          open: true,
          text: `生徒用トークン【${userTokenData.token}】は既に登録されています`,
          type: "warning",
        });
      } else {
        throw Error("status error");
      }
    } catch (error) {
      console.log(error);
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: `生徒用トークン【${userTokenData.token}】で登録できませんでした`,
        type: "error",
      });
    } finally {
      setUserTokenData({ ...userTokenData, isSending: false });
    }
  };

  const checkTokenValid = () => {
    if (
      tokenData.token !== "" &&
      tokenData.valid &&
      !tokenData.isSending &&
      !tokenData.isChecking
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleTokenDisable = async () => {
    setTokenData({ ...tokenData, isSending: true });
    try {
      await disableStudentToken();
      setTokenData({ ...tokenData, isChecking: true });
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: `生徒用トークンを無効化いたしました。`,
        type: "success",
      });
    } catch (error) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "生徒用トークンを無効化できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setTokenData({ ...tokenData, isSending: false });
    }
  };
  const handleUserDataInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.name === "nickname") {
      if (e.target.value.length === 0) {
        setUserData({
          ...userData,
          [e.target.name]: e.target.value,
          nicknameValid: false,
          nicknameHelper: "ニックネームを入力してください。",
        });
      } else if (e.target.value.length > 20) {
        setUserData({
          ...userData,
          [e.target.name]: e.target.value,
          nicknameValid: false,
          nicknameHelper: "ニックネームが長すぎます。",
        });
      } else {
        setUserData({
          ...userData,
          [e.target.name]: e.target.value,
          nicknameValid: true,
          nicknameHelper: "",
        });
      }
    }
  };
  const handleSelect = (e: any) => {
    e.preventDefault();
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  function navigateToMain() {
    history.push("/main");
  }
  const handleUpdateUserData = async () => {
    try {
      await setUserData({ ...userData, isSending: true });
      const user = auth.currentUser;
      if (user) {
        await user.updateProfile({
          displayName: userData.nickname,
        });
        await updateUserData(
          userData.nickname,
          userData.stemInterest,
          userData.progInterest
        );
        setFeedbackInfo({
          ...feedbackInfo,
          open: true,
          text: "ユーザ情報を更新いたしました。",
          type: "success",
        });
        dispatch({
          type: types.SET_USER_NICKNAME,
          payload: {
            name: userData.nickname,
          },
        });
      } else {
        throw new Error("ユーザデータがありません");
      }
      setTimeout(() => {
        navigateToMain()
      }, 1500);
    } catch (error) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "ユーザ情報を更新できませんでした。時間をおいてお試しください。",
        type: "error",
      });
    } finally {
      await setUserData({ ...userData, isSending: false });
      const setLastTime = async () => {
        const data = await getUserData();
        dispatch({
          type: types.SET_LAST_TIME,
          payload: {
            lastRecommendTime: data.lastRecommendTime,
            lastUserInfoUpdateTime: new Date(data.lastUserInfoUpdateTime),
          },
        });
      };
      setLastTime()
    }
  };

  useEffect(() => {
    const f = async () => {
      try {
        const tokenPassword: string = await getStudentToken();
        if (tokenPassword === "") {
          setTokenData({ ...tokenData, isChecking: false, isEnable: false });
        } else {
          setTokenData({
            ...tokenData,
            isChecking: false,
            isEnable: true,
            token: tokenPassword,
          });
        }
      } catch (error) {
        console.log(error);
        setTokenData({ ...tokenData, isChecking: false });
      }
    };
    if (tokenData.isChecking) {
      f();
    }
  }, [tokenData.isChecking]);

  useEffect(() => {
    const f = async () => {
      const data = await getUserData();
      setUserData({
        ...userData,
        nickname: state.name,
        stemInterest: data.stemInterest,
        progInterest: data.progInterest,
        updated: true,
      });
    };
    if (!userData.updated) {
      f();
    }
  }, [userData.updated, state.name]);

  return (
    <>
      <Container component="main" maxWidth="sm">
        <div className={classes.categoryText}>
          <Typography variant="h5" className={classes.textGrey}>
            設定
          </Typography>
        </div>
        {state.accountType === "teacher" ? (
          <Paper elevation={0} className={classes.register_paper}>
            <div className={classes.cardTitle}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  <Typography variant="h6">
                    生徒用アカウントトークン発行
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      <>
                        <div className={classes.helperText}>
                          生徒が登録するのに必要な管理トークンを発行します。トークンは有効化と無効化を切り替えることが可能で、有効化時のみ使用することができます。
                        </div>
                        <div className={classes.helperText}>
                          不正アクセスを防ぐために、生徒の登録を終えた後はトークンを無効化してください。
                        </div>
                      </>
                    }
                    placement="right"
                  >
                    <HelpOutlineIcon
                      color="primary"
                      className={classes.helperIcon}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={6}>
                <TextField
                  error={!tokenData.valid}
                  helperText={tokenData.helper}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="studentToken"
                  label="生徒用トークン"
                  autoComplete="studentToken"
                  name="studentToken"
                  value={tokenData.token}
                  onChange={handleTokenInput}
                  className={classes.tokenInput}
                  disabled={tokenData.isEnable}
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                {tokenData.isEnable ? (
                  <Typography>生徒用トークンは現在有効です。</Typography>
                ) : (
                  <Typography>
                    生徒用トークンは現在無効になっています。
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Collapse in={!tokenData.isEnable}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleTokenSubmit}
                    disabled={!checkTokenValid()}
                    className={classes.tokenSubmitButton}
                  >
                    トークンを有効化
                  </Button>
                </Collapse>
                <Collapse in={tokenData.isEnable}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={handleTokenDisable}
                    className={classes.tokenSubmitButton}
                    disabled={tokenData.isChecking || tokenData.isSending}
                  >
                    トークンを無効化
                  </Button>
                </Collapse>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <></>
        )}

        {isOutDated ? (
          <Grid container justify="center">
            <Grid item>
              <Paper className={classes.outdateInfo} variant="outlined">
                <Typography variant="h6" className={classes.centerGrey}>
                  ユーザ情報更新のお願い
                </Typography>
                <Typography variant="body1" className={classes.textGrey}>
                  Shuffle．では月に１回ユーザの皆様に属性情報の更新をお願いしております。
                  興味や技能などに変化がございましたら、設定を変更いただき更新ボタンを押してください。
                  変更がない場合は、そのまま更新ボタンを押してください。
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <Paper elevation={0} className={classes.register_paper}>
          <div className={classes.cardTitle}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item>
                <Typography variant="h6">ユーザ情報設定</Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.register_item}>
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <Typography>ニックネーム</Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={6}>
                <TextField
                  error={!userData.nicknameValid}
                  helperText={userData.nicknameHelper}
                  variant="outlined"
                  fullWidth
                  id="nickname"
                  autoComplete="nickname"
                  name="nickname"
                  value={userData.nickname}
                  onChange={handleUserDataInput}
                />
              </Grid>
            </Grid>
          </div>

          <div className={classes.register_item}>
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <Typography>算数や理科への興味</Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={6}>
                <Select
                  variant="outlined"
                  value={userData.stemInterest}
                  fullWidth
                  name="stemInterest"
                  onChange={handleSelect}
                >
                  <MenuItem value={6}>とてもある</MenuItem>
                  <MenuItem value={5}>まあまあある</MenuItem>
                  <MenuItem value={4}>少しだけある</MenuItem>
                  <MenuItem value={3}>ふつう</MenuItem>
                  <MenuItem value={2}>少しだけない</MenuItem>
                  <MenuItem value={1}>あまりない</MenuItem>
                  <MenuItem value={0}>まったくない</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </div>
          <div className={classes.register_item}>
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <Typography>プログラミングの知識と技能</Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={6}>
                <Select
                  variant="outlined"
                  value={userData.progInterest}
                  fullWidth
                  name="progInterest"
                  onChange={handleSelect}
                >
                  <MenuItem value={6}>とてもできる</MenuItem>
                  <MenuItem value={5}>まあまあできる</MenuItem>
                  <MenuItem value={4}>少しだけできる</MenuItem>
                  <MenuItem value={3}>ふつう</MenuItem>
                  <MenuItem value={2}>少しだけできない</MenuItem>
                  <MenuItem value={1}>あまりできない</MenuItem>
                  <MenuItem value={0}>まったくできない</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateUserData}
                  disabled={userData.isSending || !userData.nicknameValid}
                  className={classes.tokenSubmitButton}
                >
                  ユーザ情報を更新
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>

        {state.accountType === "student" ? (
          <Paper elevation={0} className={classes.register_paper}>
            <div className={classes.cardTitle}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  <Typography variant="h6">先生・指導者の追加</Typography>
                </Grid>
              </Grid>
            </div>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={6}>
                <TextField
                  error={!userTokenData.valid}
                  helperText={userTokenData.helper}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="生徒用トークン"
                  value={userTokenData.token}
                  onChange={handleUserTokenInput}
                  className={classes.tokenInput}
                  disabled={userTokenData.isSending}
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.tokenSubmitButton}
                  onClick={handleUserTokenSubmit}
                  disabled={
                    !userTokenData.valid || userTokenData.token.length === 0
                  }
                >
                  先生を追加
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <></>
        )}
      </Container>
      <FeedbackBar {...feedbackInfo} handleClose={handleClose} />
    </>
  );
};

export default SettingsPage;
