import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";

export const datetoString = (date: Date): string => {
  return ` ${date.getFullYear()}年 ${
    date.getMonth() + 1
  }月 ${date.getDate()}日`;
};

export const DatetimeParse: React.FC<{
  dateString: Date | null;
  index: number | null;
}> = ({ dateString, index }) => {
  const [date, setDate] = useState<Date | null>(null);
  useEffect(() => {
    if (dateString !== null) {
      setDate(new Date(dateString));
    }
  }, [dateString]);

  return (
    <>
      {date ? (
        <>
          {index !== null ? (
            <Typography>
              {`${index + 1}回前の利用時刻：${date.getFullYear()}/${
                date.getMonth() + 1
              }/${date.getDate()}` +
                " " +
                ("0" + `${date.getHours()}`).slice(-2) +
                ":" +
                ("0" + `${date.getMinutes()}`).slice(-2)}
            </Typography>
          ) : (
            <Typography>
              {`前回の利用時刻：${date.getFullYear()}/${
                date.getMonth() + 1
              }/${date.getDate()}` +
                " " +
                ("0" + `${date.getHours()}`).slice(-2) +
                ":" +
                ("0" + `${date.getMinutes()}`).slice(-2)}
            </Typography>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const DatetimeParseList: React.FC<{
  dateString: Array<Date> | null;
}> = ({ dateString }) => {
  return (
    <>
      {dateString?.map((value, index) => (
        <DatetimeParse dateString={value} key={index} index={index} />
      ))}
    </>
  );
};

export default DatetimeParse;
