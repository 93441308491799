import React, { createContext, useReducer } from "react";
import { VideoCardProps } from "../components/common/videoCard";
import reducer from "./reducer";
import { Actions, State } from "./types";

type Props = {
  children: React.ReactNode;
};
type Dispatch = React.Dispatch<Actions>;

const initialState: State = {
  name: "",
  nickname: "",
  uid: "",
  email: "",
  emailVerified: false,
  sidebarState: false,
  signInState: "",
  accountType: "",
  lastRecommendTime: Array<Date>(),
  lastUserInfoUpdateTime: null,
  recommendReview: "",
  recommendCategory: "",
  recommendWeight: 0,
  recommendResult: Array<VideoCardProps>(),
};

export const Store = createContext<{ state: State; dispatch: Dispatch }>({
  state: initialState,
  dispatch: () => null,
});
const { Provider } = Store;

export default function StateProvider(props: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
}
