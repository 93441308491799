//生徒を管理するページ
import React, { FC, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { DataGrid, GridColDef, jaJP } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

// self-modules
import { Store } from "../store";
import { getStudentsUsageData } from "../utils/http-requests";
import { usageInformation } from "../utils/types";
import { FeedbackBar } from "../utils/feedback";

//テーブルデータの型定義
const columns: GridColDef[] = [
  { field: "fullname", headerName: "氏名", width: 200 },
  { field: "nickname", headerName: "ニックネーム", width: 200 },
  { field: "all_reviews_num", headerName: "視聴本数（累計）", width: 200 },
  { field: "month_reviews_num", headerName: "視聴本数（当月）", width: 200 },
  {
    field: "all_video_viewTime",
    headerName: "視聴時間（分/累計）",
    width: 220,
  },
  {
    field: "month_video_viewTime",
    headerName: "視聴時間（分/当月）",
    width: 220,
  },
];

const useStyles = makeStyles({
  centering: {
    textAlign: "center",
  },
  pageTitle: {
    textAlign: "center",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  textGrey: {
    color: "#616161",
  },
  helperIcon: {
    fontSize: "1.5rem",
  },
  helperText: {
    padding: "0.5rem",
    fontSize: "1rem",
  },
});

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  jaJP
);

const ManageStudentsPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useContext(Store);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [usageData, setUsageData] = useState([] as Array<usageInformation>);
  const [isSending, setIsSending] = useState(true);
  const [userId, setUserId] = useState("");
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  useEffect(() => {
    const f = async () => {
      setIsSending(true);
      try {
        const data: Array<usageInformation> = await getStudentsUsageData();
        setUsageData(data);
      } catch {
        setFeedbackInfo({
          ...feedbackInfo,
          open: true,
          text: "生徒の利用情報の取得が出来ませんでした。ブラウザをリロードして再度お試しください。",
          type: "error",
        });
      } finally {
        setIsSending(false);
      }
    };
    f();
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //テーブルクリック時のイベント処理
  const handleTableClick = (
    params: any,
    e: React.MouseEvent<Element, MouseEvent>
  ) => {
    const e_target = e.target as HTMLElement;
    setUserId(params.row.userId);
    setAnchorEl(e_target);
  };

  const handleMenuClick = () => {
    if (userId === "") {
      history.push("/portfolio-list");
    } else {
      history.push("/portfolio-list?id=" + userId);
    }
  };

  const handleMenuClickMyPage = () => {
    if (userId === "") {
      history.push("/mypage");
    } else {
      history.push("/mypage?id=" + userId);
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.pageTitle}>
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Typography variant="h5" className={classes.textGrey}>
                児童・生徒管理テーブル
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <>
                    <div className={classes.helperText}>
                      生徒の利用情報は1時間に1度更新されます
                    </div>
                  </>
                }
                placement="right"
              >
                <HelpOutlineIcon
                  color="primary"
                  className={classes.helperIcon}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </div>
        {isSending ? (
          <>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <div className={classes.centering}>
                  <CircularProgress
                    style={{ marginTop: "10rem" }}
                    size="3rem"
                  />
                </div>
                <Typography className={classes.textGrey}>
                  生徒の利用情報を計算中...
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <div style={{ height: 600 }}>
            <ThemeProvider theme={theme}>
              <DataGrid
                disableSelectionOnClick
                rows={usageData}
                columns={columns}
                onCellClick={handleTableClick}
              />
            </ThemeProvider>
          </div>
        )}
      </Container>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <div className={classes.centering}>
          <Typography className={classes.textGrey}>ユーザメニュー</Typography>
        </div>
        <Divider />
        <MenuItem onClick={handleMenuClick}>ポートフォリオ一覧</MenuItem>
        <MenuItem onClick={handleMenuClickMyPage}>マイページ</MenuItem>
      </Menu>
      <FeedbackBar {...feedbackInfo} handleClose={handleClose} />
    </>
  );
};

export default ManageStudentsPage;
