import React, { FC } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type FeedbackInfo = {
  text: string;
  type: "success" | "error" | "warning" | "info";
  open: boolean;
  handleClose: (event?: React.SyntheticEvent, reason?: string) => void;
  autoHideDuration: number | null;
};

export const FeedbackBar: FC<FeedbackInfo> = (props: FeedbackInfo) => {
  return (
    <>
      <Snackbar
        open={props.open}
        autoHideDuration={props.autoHideDuration}
        onClose={props.handleClose}
      >
        <Alert onClose={props.handleClose} severity={props.type}>
          {props.text}
        </Alert>
      </Snackbar>
    </>
  );
};
