import React, { FC, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";
import { blue, green } from "@material-ui/core/colors";
import {
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";

// import self modules
import { auth } from "../utils/firebase";
import { Store } from "../store";
import theme_app from "../Theme";
import { FeedbackBar } from "../utils/feedback";

const useStyles = makeStyles({
  register_paper: {
    marginTop: "0 rem",
  },
  mail_icon: {
    fontSize: theme_app.spacing(10),
    margin: theme_app.spacing(5),
    color: blue[200],
  },
  centering: { textAlign: "center" },
  parag: { display: "inline-block" },
  submit: { marginTop: theme_app.spacing(2) },
  circle: { marginTop: theme_app.spacing(2) },
});

type FormType = {
  name: string;
  value: string;
};

const MailRegisterPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { state } = useContext(Store);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [emailValidShow, setEmailValidShow] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // if SignInState is "SignIn", move to main page
  useEffect(() => {
    if (state.signInState === "SignIn") {
      history.push("/main");
    }
  }, [state.signInState]);

  // handle functions
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  const handleFormInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEmail(e.target.value);
    if (e.target.value.length != 0) {
      const valid = e.target.value.match(/^\S+@\S+\.\S+$/) ? true : false;
      if (valid) {
        setEmailValid(true);
        if (emailValidShow) {
          setEmailValidShow(false);
        }
      } else {
        setEmailValid(false);
      }
    }
  };
  const handleEmailValid = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!emailValid) {
      setEmailValidShow(true);
    }
  };
  const handleSendEmail = async (e: React.MouseEvent) => {
    if (!isSending) {
      e.preventDefault();
      setIsSending(true);
      try {
        console.log(process.env.REACT_APP_SERVER_ADDRESS + "/register");
        await auth.sendSignInLinkToEmail(email, {
          url: process.env.REACT_APP_SERVER_ADDRESS + "/register",
          handleCodeInApp: true,
        });
        localStorage.setItem("emailForSignIn", email);
        setFeedbackInfo({
          ...feedbackInfo,
          open: true,
          text: `確認メールを ${email} に送信いたしました。`,
          type: "success",
        });
      } catch (e) {
        setFeedbackInfo({
          ...feedbackInfo,
          open: true,
          text:
            "確認メールを送信できませんでした。メールアドレスが正しいか確認してください。" +
            e,
          type: "error",
        });
      }
    }
    setIsSending(false);
  };

  return (
    <>
      <Container component="main" maxWidth="sm">
        <Grid container alignItems="center" justify="center">
          <Grid item xs={9}>
            <div className={classes.centering}>
              <MailIcon className={classes.mail_icon} color="action" />
            </div>
            <div className={classes.centering}>
              <Typography className={classes.parag}>
                ご入力のメールアドレスに
              </Typography>
              <Typography className={classes.parag}>
                ユーザー登録用のURLを送信します
              </Typography>
            </div>
            <div>
              <TextField
                error={emailValidShow}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="メールアドレス"
                helperText={
                  emailValidShow ? "正しいメールアドレスを入力してください" : ""
                }
                autoComplete="email"
                name="name"
                value={email}
                onChange={handleFormInput}
                onBlur={handleEmailValid}
              />
              <Button
                className={classes.submit}
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  email.length > 0 && emailValid && !isSending ? false : true
                }
                onClick={handleSendEmail}
              >
                確認メールを送信
              </Button>
              <div className={classes.centering}>
                {isSending ? (
                  <CircularProgress className={classes.circle} size={60} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <FeedbackBar {...feedbackInfo} handleClose={handleClose} />
    </>
  );
};

export default MailRegisterPage;
