// httpリクエストをする関数を定義
import React from "react";
import axios from "axios";
import {
  videoInformation,
  videoHistory,
  portfolioInformation,
  usageInformation,
} from "./types";
import { VideoCardProps } from "../components/common/videoCard";
import { VideoFeedbackProps } from "../components/common/videoFeedbackCard";
import { VideoCardDetailProps } from "../components/portfolio/videoCardDetail";
import {
  PortfolioCardProps,
  PortfolioCardRaw,
  portfolioCardParser,
} from "../components/portfolio/portfolio-card";
import { getUserIdToken } from "./firebase";

// デバッグ環境の場合は専用のurlを使う
const API_PREFIX_GENERAL = process.env.REACT_APP_DEBUG
  ? process.env.REACT_APP_DEBUG_BACKEND_SERVER_GENERAL
  : `${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/general`;

const API_PREFIX_RECOMMEND = process.env.REACT_APP_DEBUG
  ? process.env.REACT_APP_DEBUG_BACKEND_SERVER_RECOMMEND
  : `${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}/api/recommend`;

// 動画の情報を取得する関数
export const getVideoHistory = async (userId?: string) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const httpData = await axios.post(
      `${API_PREFIX_GENERAL}/getVideoHistory`,
      {
        userId,
      },
      config
    );
    const videoData: videoHistory = httpData.data;
    return videoData;
  } catch (error) {
    throw new Error("getVideoInformationFailed");
  }
};

// 動画の情報を取得する関数
export const getVideoInformation = async (videoId: string) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const httpData = await axios.post(
      `${API_PREFIX_GENERAL}/getVideoInformation`,
      {
        videoId,
      },
      config
    );
    const videoData: videoInformation = httpData.data;
    return videoData;
  } catch (error) {
    throw new Error("getVideoInformationFailed");
  }
};

// 管理トークンをチェックする関数
export const verifyToken = async (token: string) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    await axios.post(
      `${API_PREFIX_GENERAL}/verifyToken`,
      {
        token,
      },
      config
    );
  } catch (error) {
    throw new Error("verifyTokenFailed");
  }
};

// ユーザ情報を登録する関数
export const setUserData = async (
  token: string,
  fullname: string,
  nickname: string,
  birthday: string,
  degree: number,
  stemInterest: number,
  progInterest: number,
  prefecture: string
) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    await axios.post(
      `${API_PREFIX_GENERAL}/setUserData`,
      {
        token,
        fullname,
        nickname,
        birthday,
        degree,
        stemInterest,
        progInterest,
        prefecture,
      },
      config
    );
  } catch (error) {
    throw new Error("setUserDataFailed");
  }
};

// ユーザ情報を取得する関数
export const getUserData = async () => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const userData = await axios.post(
      `${API_PREFIX_GENERAL}/getUserData`,
      {},
      config
    );
    return userData.data;
  } catch (error) {
    throw error;
  }
};

// ユーザ情報を更新する関数
export const updateUserData = async (
  nickname: string,
  stemInterest: number,
  progInterest: number
) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const userData = await axios.post(
      `${API_PREFIX_GENERAL}/updateUserData`,
      { nickname, stemInterest, progInterest },
      config
    );
    return userData.data;
  } catch (error) {
    throw error;
  }
};

// 動画の感想を保存する関数
export const saveVideoReview = async (
  videoId: string,
  rating: number,
  reviewText: string,
  isFavorite: boolean,
  isPublic: boolean,
  viewTime: number,
  category?: string,
  recommendText?: string
) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    await axios.post(
      `${API_PREFIX_GENERAL}/saveVideoReview`,
      {
        videoId,
        rating,
        reviewText,
        isFavorite,
        isPublic,
        viewTime,
        category,
        recommendText,
      },
      config
    );
  } catch (error) {
    throw new Error("saveVideoReviewFailed");
  }
};

// 生徒用トークンを登録する関数
export const enableStudentToken = async (
  tokenPassword: string
) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    await axios.post(
      `${API_PREFIX_GENERAL}/enableStudentToken`,
      {
        tokenPassword,
      },
      config
    );
  } catch (error) {
    throw error;
  }
};

//　生徒用トークンを削除する関数
export const disableStudentToken = async () => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    await axios.post(`${API_PREFIX_GENERAL}/disableStudentToken`, {}, config);
  } catch (error) {
    throw error;
  }
};

// 生徒用トークンをを取得する関数
export const getStudentToken = async () => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_GENERAL}/getStudentToken`,
      {},
      config
    );
    const tokenPassword: string = request.data.studentToken;
    return tokenPassword;
  } catch (error) {
    throw error;
  }
};

// 生徒用トークンで先生を追加する関数
export const addTeacher = async (token: string) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_GENERAL}/addTeacher`,
      { token },
      config
    );
    const status: number = request.status;
    return status;
  } catch (error) {
    throw error;
  }
};

// 公開動画感想を取得する関数
export const getPublicReviews = async () => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_GENERAL}/getPublicReviews`,
      {},
      config
    );
    const videos: Array<VideoFeedbackProps> = request.data;
    return videos;
  } catch (error) {
    throw error;
  }
};

// スコアが高い動画を取得する関数
export const getHighScoreVideos = async () => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_GENERAL}/getHighScoreVideos`,
      {},
      config
    );
    const videos: Array<VideoCardProps> = request.data;
    return videos;
  } catch (error) {
    throw error;
  }
};

// 生徒ユーザ一覧を取得する関数
export const getStudents = async () => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_GENERAL}/getStudentsList`,
      {},
      config
    );
    const studentsList = request.data;
    return studentsList;
  } catch (error) {
    throw error;
  }
};

// 指定した時刻範囲内のレビューデータを取得する関数
export const getReviews = async (
  dateFrom: Date,
  dateTo: Date,
  category: string,
  userId?: string,
  byAscending?: boolean
) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_GENERAL}/getReviews`,
      {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString(),
        category,
        userId,
        byAscending,
      },
      config
    );
    const reviews: Array<VideoCardDetailProps> = request.data;
    return reviews;
  } catch (error) {
    throw error;
  }
};

// ポートフォリオを取得するリクエスト
export const getPortfolio = async (
  portfolioId: string,
  userId?: string
) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_GENERAL}/getPortfolio`,
      {
        portfolioId,
        userId,
      },
      config
    );
    const content: portfolioInformation = request.data.portfolio;
    const targetFullname: string = request.data.targetFullname;
    return { content, targetFullname };
  } catch (error) {
    throw error;
  }
};

// ポートフォリオを新規登録，または更新する関数
export const savePortfolio = async (
  review: string,
  scoreContinueLearning: number,
  scoreSelfLearning: number,
  isUpdate: boolean,
  title?: string,
  dateFrom?: Date,
  dateTo?: Date,
  category?: string,
  portfolioId?: string
) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_GENERAL}/savePortfolio`,
      {
        review,
        scoreContinueLearning,
        scoreSelfLearning,
        isUpdate,
        title,
        dateFrom: dateFrom ? dateFrom.toISOString() : undefined,
        dateTo: dateTo ? dateTo.toISOString() : undefined,
        category,
        portfolioId,
      },
      config
    );
    const latestPortfolioId: string = request.data.portfolioId;
    return latestPortfolioId;
  } catch (error) {
    throw error;
  }
};

// ポートフォリオの一覧を取得するリクエスト
export const getPortfolioList = async (userId?: string) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_GENERAL}/getPortfolioList`,
      {
        userId,
      },
      config
    );
    const raw: Array<PortfolioCardRaw> = request.data.portfolio;
    const content: Array<PortfolioCardProps> = raw.map(portfolioCardParser);
    const targetFullname: string = request.data.targetFullname;
    return { content, targetFullname };
  } catch (error) {
    throw error;
  }
};

// ポートフォリオの一覧を取得するリクエスト
export const getStudentsUsageData = async () => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_GENERAL}/getStudentUsageData`,
      {},
      config
    );
    const data: Array<usageInformation> = request.data;
    return data;
  } catch (error) {
    throw error;
  }
};

/* HTTP request to Recommend container */

// 感想からレコメンドをする関数
export const getRecommendation = async (
  review: string,
  category: string,
  weight: number
) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_RECOMMEND}/recommendation`,
      { review, category, weight },
      config
    );
    const videos: Array<VideoCardProps> = request.data;
    return videos;
  } catch (error) {
    throw error;
  }
};

// 生徒ユーザをグルーピングする関数
export const groupingStudents = async (
  groupNum: number,
  weight: number
) => {
  try {
    let idToken = await getUserIdToken()
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const request = await axios.post(
      `${API_PREFIX_RECOMMEND}/grouping`,
      { groupNum, weight },
      config
    );
    const studentsData = request.data;
    return studentsData;
  } catch (error) {
    throw error;
  }
};
