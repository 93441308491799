// レコメンド機能のページ
import {
  Box,
  Button, CircularProgress, Collapse, Container, Divider, Grid, Slider, TextField, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC, useContext, useEffect, useState } from "react";
import CategoryNestedPulldown from "../components/common/category";
import VideoCard, { VideoCardProps } from "../components/common/videoCard";
// self modules
import { Store } from "../store";
import types from "../store/types";
import theme_app from "../Theme";
import { FeedbackBar } from "../utils/feedback";
import { getRecommendation } from "../utils/http-requests";


const useStyles = makeStyles({
  searchTitle: {
    marginTop: theme_app.spacing(3),
    color: "#616161",
  },
  continueText: {
    textAlign: "center",
    marginBottom: theme_app.spacing(2),
  },
  r_box: {
    display: "flex",
  },
  divide: {
    margin: theme_app.spacing(2, 0, 2),
  },
  submitButton: {
    margin: theme_app.spacing(1, 0, 2),
  },
  textGrey: {
    color: "#616161",
  },
  categoryText: {
    textAlign: "center",
    paddingTop: "1rem",
  },
  sliderText: {
    color: "#616161",
    textAlign: "center",
  },
  selectForm: {
    margin: theme_app.spacing(2, 0, 2),
  },
  root: {
    "&$disabled": {
      color: "#000000",
      backgroundColor: "#ffffff",
    },
  },
  disabled: {},
  categoryButton: {
    marginLeft: theme_app.spacing(2),
  },
});

const RecommendPage: FC = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);
  const show_video_max = 10;
  const [review, setReview] = useState("");
  const [category, setCategory] = useState("");
  const [recommendWeight, setRecommendWeight] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showNum, setShowNum] = useState(3);
  const [videos, setVideos] = useState(Array<VideoCardProps>());

  // ------ Feedback Bar setting -----
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };
  // ---------------------------------

  // 振り返りコメントの入力ハンドラ
  const handleInput = (
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    setReview(e.currentTarget.value);
  };

  // レコメンドボタンを押した時のハンドラ
  const handleInputButton = async (e: React.FormEvent) => {
    if (isSending === false) {
      try {
        setIsSending(true);
        setShowNum(3);
        setRecommendData();
        // weightは値域を0-100から0-1へ変換
        const convertedWeight = recommendWeight / 100;
        const data = await getRecommendation(
          review,
          category,
          convertedWeight
        );
        setRecommendResult(data);
        await setVideos(data);
      } catch (e) {
        setFeedbackInfo({
          ...feedbackInfo,
          open: true,
          text: "サーバにアクセスできないため、レコメンドが出来ませんでした。時間をおいて再度お試しください。",
          type: "error",
        });
      } finally {
        setIsSending(false);
      }
    }
  };

  const handleSelect = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setCategory(e.target.value);
  };

  // スライダー操作時にレコメンドウェイトを変更
  const handleSlider = (event: any, newValue: number | number[]) => {
    setRecommendWeight(newValue as number);
  };

  // レコメンド結果の表示数を増やす関数
  const increaseShowNum = () => {
    if (showNum + 4 > show_video_max) {
      setShowNum(show_video_max);
    } else {
      setShowNum(showNum + 4);
    }
  };

  // レコメンド時のデータをグローバルに保持
  const setRecommendData = () => {
    dispatch({
      type: types.SET_RECOMMEND_DATA,
      payload: {
        recommendReview: review,
        recommendCategory: category,
        recommendWeight: recommendWeight,
      },
    });
  };

  // レコメンド時の結果をグローバルに保持
  const setRecommendResult = (recommendResult: Array<VideoCardProps>) => {
    dispatch({
      type: types.SET_RECOMMEND_RESULT,
      payload: {
        recommendResult,
      },
    });
  };

  // グローバルに保持されたレコメンド情報を読み込み
  useEffect(() => {
    setReview(state.recommendReview);
    setCategory(state.recommendCategory);
    setRecommendWeight(state.recommendWeight);
    setVideos(state.recommendResult);
  }, []);

  const ContinueButton: FC = () => {
    if (
      videos.length === 0 ||
      videos.length <= showNum ||
      showNum === show_video_max
    ) {
      return <></>;
    } else {
      return (
        <Grid container alignItems="center" justify="center">
          <Grid item>
            <Box justifyContent="center">
              <Button
                className={classes.continueText}
                onClick={increaseShowNum}
              >
                <Typography variant="h6">続きを表示</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      );
    }
  };

  const checkSendButtonDisable = () => {
    if (!isSending && review !== "" && category !== "") {
      return false;
    } else {
      return true;
    }
  };
  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <div className={classes.categoryText}>
          <Typography variant="h5" className={classes.textGrey}>
            レコメンドページ
          </Typography>
        </div>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={8}>
            <Typography variant="h6" className={classes.searchTitle}>
              コメント入力
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="query"
              name="query"
              multiline
              rows={5}
              onChange={handleInput}
              value={review}
            />
            <Grid container alignItems="center" className={classes.selectForm}>
              <Grid item xs={2}>
                <Typography variant="h6" className={classes.textGrey}>
                  授業内容
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  variant="outlined"
                  value={category}
                  fullWidth
                  onChange={handleSelect}
                  disabled
                  InputProps={{
                    classes: {
                      root: classes.root,
                      disabled: classes.disabled,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  value={category}
                  onClick={handleCategoryClick}
                  fullWidth
                  className={classes.categoryButton}
                >
                  選択
                </Button>
                <CategoryNestedPulldown
                  anchor={anchorEl}
                  setAnchor={setAnchorEl}
                  setCategory={setCategory}
                  left={false}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.selectForm}
          >
            <Grid item xs={3}>
              <Typography variant="body1" className={classes.sliderText}>
                ユーザー情報をもとにした
              </Typography>
              <Typography variant="h6" className={classes.sliderText}>
                「知識・技能」重視
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Slider
                value={recommendWeight}
                valueLabelDisplay="auto"
                step={10}
                marks
                min={0}
                max={100}
                onChange={handleSlider}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" className={classes.sliderText}>
                視聴履歴をもとにした
              </Typography>
              <Typography variant="h6" className={classes.sliderText}>
                「興味・関心」重視
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              className={classes.submitButton}
              onClick={handleInputButton}
              disabled={checkSendButtonDisable()}
            >
              レコメンド
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divide} />
        <Grid container alignItems="center" justify="center">
          <Grid item>
            <Collapse in={isSending}>
              <Grid container alignItems="center" justify="center">
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
              <Typography variant="h6" className={classes.searchTitle}>
                おすすめのコンテンツを計算中...
              </Typography>
            </Collapse>
          </Grid>
        </Grid>
        {videos.slice(0, showNum).map((value, index) => {
          return <VideoCard {...value} isRecommended={true} key={index} />;
        })}
        <ContinueButton />
      </Container>
      <FeedbackBar {...feedbackInfo} handleClose={handleClose} />
    </>
  );
};
export default RecommendPage;
