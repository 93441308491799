import React, { FC } from "react";
import { Typography, Container, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme_app from "../Theme";

const useStyles = makeStyles({
  textGrey: {
    color: "#616161",
  },
  title: {
    padding: theme_app.spacing(3),
    color: "#616161",
  },
});

const PrivacyPolicyPage: FC = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="md">
        <Typography variant="h5" className={classes.title}>
          「Shuffle.」サービスにおける個人情報の取り扱いについて
        </Typography>
        <Typography variant="h6" className={classes.textGrey}>
          合同会社MAZDA Incredible Lab
          <br />
          <br />
        </Typography>
        <Typography variant="body1" className={classes.textGrey}>
          AIリコメンドコンテンツ「Shuffle.（シャッフル・テン）」を提供する合同会社Mazda
          Incredible
          Lab（以下，「当社」といいます）は，本サービスにおいて，利用者さまの個人情報を適正に管理することが社会的責任であるとの認識に基づき，以下の通り取り扱いを徹底いたします。
          <br />
          <br />
          １　利用目的
          <br /> （１） 「Shuffle.」サービス（トライアルも含む）に関して
          <br />
          　　• サービス提供のための利用者登録
          <br />
          　　• 学習ログ・アンケート結果の分析業務の外部業者への委託
          <br />
          　　•
          研究のための学術機関への提供（個人を特定識別できないように加工した上での提供）
          <br />
          <br />
          （２）問い合わせ
          <br />
          　　• 内容の確認および対応・報告の連絡
          <br />
          　　•
          ご希望のあった資料の発送・各種情報の提供（DM，FAX，E-mail，電話等による）
          <br />
          　　•
          お問い合わせに基づく利用者さまへの提案に向けた活動（顧客ニーズの分析、当社商品・サービスの開発、評価収集・分析「Shuffle.」提案活動など）
          <br />
          <br />
          （３）無料体験の申込
          <br />
          　　• 無料体験をご利用いただくための利用者さまへの連絡
          <br />
          　　• 利用後のアンケート依頼
          <br />
          　　• 「Shuffle.」サービスのご案内
          <br />
          <br />
          ２　個人情報の任意性
          <br />
          　利用者さまによる個人情報のご提供は任意ですがShuffle.提供をいただけない個人情報がある場合Shuffle.上記利用目的を達成するに支障をきたす場合があります。
          <br />
          <br />
          ３　個人情報の第三者への提供
          <br />
          　「Shuffle.」サービスに関して，研究のための学術機関への提供することがあります。その場合，個人を特定識別できないように加工した上で提供します。
          <br />
          <br />
          ４　外部業者への個人情報の委託
          <br />
          　「Shuffle.」サービスの学習ログ・アンケート結果の分析業務を，外部業者に委託することがあります。なお，委託先の選定は，当社の個人情報取扱ルールにもとづき，安全管理体制を確認した上で行います。
          <br />
          <br />
          ５　個人が容易に認識できない方法による個人情報の取得
          <br />
          　「Shuffle.」サービスでは，クッキーなどの個人が容易に認識できない方法により個人情報を取得することはありません。
          <br />
          <br />
          ６　個人情報の開示・訂正・削除・利用停止等への対応
          <br />
          <br />
          　当社では，利用者さまご本人からの求めにより，開示対象個人情報の利用目的の通知，開示，内容の訂正・追加または削除，消去，利用の停止および第三者への提供の停止（以下「開示等」といいます）に応じます。開示等をお求めの場合は，以下の宛先にお問い合わせください。
          <br />
          <br />
          ◯　お問い合わせ先：合同会社MAZDA Incredible
          Lab（https://mazda-lab.co.jp/）
          <br />
          <br />
        </Typography>
      </Container>
    </>
  );
};

export default PrivacyPolicyPage;
