import React, { FC, useEffect, useContext, useState } from "react";
import { Typography, Grid, Container, Paper, List } from "@material-ui/core";
import VideoCard, { VideoCardProps } from "../components/common/videoCard";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import theme_app from "../Theme";
import { blue } from "@material-ui/core/colors";
import { getVideoHistory } from "../utils/http-requests";
import { Store } from "../store";
import { DatetimeParseList } from "../components/common/datetime";
import queryString from "query-string";

const useStyles = makeStyles({
  centering: {
    textAlign: "center",
  },
  categoryText: {
    textAlign: "center",
    paddingTop: "1rem",
  },
  gridContainer: {
    marginTop: theme_app.spacing(2),
  },
  scrollable: {
    overflowY: "scroll",
    maxHeight: "35rem",
    minHeight: "35rem",
    borderColor: blue[200],
    borderWidth: "3px",
    backgroundColor: "#fafafa",
  },
  textGrey: {
    color: "#616161",
  },
});

const MyPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useContext(Store);
  const [opinion, setOpinion] = useState(Array<VideoCardProps>());
  const [favorite, setFavorite] = useState(Array<VideoCardProps>());
  const [studentFullname, setStudentFullname] = useState("");
  useEffect(() => {
    const f = async () => {
      const qs = queryString.parse(history.location.search);
      // idがあればそのユーザの一覧を取得
      const result = await getVideoHistory(
        String(qs.id || "")
      );
      setOpinion(result.history);
      setFavorite(result.favorite);
      setStudentFullname(result.targetFullname);
    };
    f();
  }, []);

  return (
    <>
      {( !studentFullname &&
      <>
        <DatetimeParseList dateString={state.lastRecommendTime} />
      </>
      )}
      <Container maxWidth="lg">
        <div className={classes.categoryText}>
          <Typography variant="h5" className={classes.textGrey}>
            {`${studentFullname !== "" ? studentFullname + " さんの" : ""}`}マイページ
          </Typography>
        </div>
        <Grid
          container
          alignItems="stretch"
          spacing={2}
          className={classes.gridContainer}
        >
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.centering}>
              <Typography variant="h6" className={classes.textGrey}>
                感想を保存した動画
              </Typography>
            </div>
            <Paper className={classes.scrollable} variant="outlined" square>
              <List>
                {opinion.map((value, index) => {
                  return (
                    <VideoCard {...value} key={index} backgroundColor="white" />
                  );
                })}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.centering}>
              <Typography variant="h6" className={classes.textGrey}>
                お気に入りした動画
              </Typography>
            </div>

            <Paper className={classes.scrollable} variant="outlined" square>
              <List>
                {favorite.map((value, index) => {
                  return (
                    <VideoCard {...value} key={index} backgroundColor="white" />
                  );
                })}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MyPage;
