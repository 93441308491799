// 登録画面の各フィールドを定義
import React, { FC, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  ListSubheader,
} from "@material-ui/core";

interface selectorProps {
  value: registerInformation;
  setter: (arg: any) => void;
}

export interface registerInformation {
  fullname: string;
  password: string;
  passwordConfirm: string;
  nickname: string;
  prefectures: string;
  birthYear: number;
  birthMonth: number;
  birthDay: number;
  degree: number;
  stemInterest: number;
  progInterest: number;
  managementToken: string;
}

export const StemInterestSelector: FC<selectorProps> = (
  props: selectorProps
) => {
  const handleSelect = (e: any) => {
    e.preventDefault();
    props.setter({ ...props.value, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Typography style={{ marginTop: "1rem" }}>算数や理科への興味*</Typography>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={6}>
          <Select
            variant="outlined"
            value={props.value.stemInterest}
            fullWidth
            name="stemInterest"
            onChange={handleSelect}
          >
            <MenuItem value={6}>とてもある</MenuItem>
            <MenuItem value={5}>まあまあある</MenuItem>
            <MenuItem value={4}>少しだけある</MenuItem>
            <MenuItem value={3}>ふつう</MenuItem>
            <MenuItem value={2}>少しだけない</MenuItem>
            <MenuItem value={1}>あまりない</MenuItem>
            <MenuItem value={0}>まったくない</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </>
  );
};

export const ProgramInterestSelector: FC<selectorProps> = (
  props: selectorProps
) => {
  const handleSelect = (e: any) => {
    e.preventDefault();
    props.setter({ ...props.value, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Typography style={{ marginTop: "1rem" }}>
        プログラミングの知識と技能*
      </Typography>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={6}>
          <Select
            variant="outlined"
            value={props.value.progInterest}
            fullWidth
            name="progInterest"
            onChange={handleSelect}
          >
            <MenuItem value={6}>とてもできる</MenuItem>
            <MenuItem value={5}>まあまあできる</MenuItem>
            <MenuItem value={4}>少しだけできる</MenuItem>
            <MenuItem value={3}>ふつう</MenuItem>
            <MenuItem value={2}>少しだけできない</MenuItem>
            <MenuItem value={1}>あまりできない</MenuItem>
            <MenuItem value={0}>まったくできない</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </>
  );
};

export const DegreeSelector: FC<selectorProps> = (props: selectorProps) => {
  const handleSelect = (e: any) => {
    e.preventDefault();
    props.setter({ ...props.value, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Typography style={{ marginTop: "1rem" }}>学年*</Typography>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={6}>
          <Select
            variant="outlined"
            value={props.value.degree}
            fullWidth
            name="degree"
            onChange={handleSelect}
            label="学年"
          >
            <MenuItem value={1}>小学生未満</MenuItem>
            <ListSubheader>小学生</ListSubheader>
            <MenuItem value={2}>小学１年生</MenuItem>
            <MenuItem value={3}>小学２年生</MenuItem>
            <MenuItem value={4}>小学３年生</MenuItem>
            <MenuItem value={5}>小学４年生</MenuItem>
            <MenuItem value={6}>小学５年生</MenuItem>
            <MenuItem value={7}>小学６年生</MenuItem>
            <ListSubheader>中学生</ListSubheader>
            <MenuItem value={8}>中学１年生</MenuItem>
            <MenuItem value={9}>中学２年生</MenuItem>
            <MenuItem value={10}>中学３年生</MenuItem>
            <ListSubheader>高校生</ListSubheader>
            <MenuItem value={11}>高校１年生</MenuItem>
            <MenuItem value={12}>高校２年生</MenuItem>
            <MenuItem value={13}>高校３年生</MenuItem>
            <MenuItem value={14}>大学生</MenuItem>
            <MenuItem value={15}>指導者(先生等)</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </>
  );
};

export const BirthSelector: FC<selectorProps> = (props: selectorProps) => {
  const handleSelect = (e: any) => {
    e.preventDefault();
    props.setter({ ...props.value, [e.target.name]: e.target.value });
  };

  // list for generating select options
  const range = (start: number, end: number) =>
    Array.from({ length: end - start + 1 }, (v, k) => k + start);
  const year_list = range(1950, 2021);
  const month_list = range(1, 12);
  const day_list = range(1, 31);

  return (
    <>
      <Typography style={{ marginTop: "1rem" }}>生年月日*</Typography>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={3}>
          <Select
            variant="outlined"
            value={props.value.birthYear}
            fullWidth
            name="birthYear"
            onChange={handleSelect}
          >
            {year_list.map((value, index) => {
              return (
                <MenuItem value={value} key={index}>
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="inherit">{value}</Typography>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Typography
          variant="h6"
          style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
        >
          年
        </Typography>
        <Grid item xs={2}>
          <Select
            variant="outlined"
            value={props.value.birthMonth}
            fullWidth
            name="birthMonth"
            onChange={handleSelect}
          >
            {month_list.map((value, index) => {
              return (
                <MenuItem value={value} key={index}>
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="inherit">{value}</Typography>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Typography
          variant="h6"
          style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
        >
          月
        </Typography>
        <Grid item xs={2}>
          <Select
            variant="outlined"
            value={props.value.birthDay}
            fullWidth
            name="birthDay"
            onChange={handleSelect}
          >
            {day_list.map((value, index) => {
              return (
                <MenuItem value={value} key={index}>
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="inherit">{value}</Typography>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Typography
          variant="h6"
          style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
        >
          日
        </Typography>
      </Grid>
    </>
  );
};

export const PrefecturesSelector: FC<selectorProps> = (
  props: selectorProps
) => {
  const handleSelect = (e: any) => {
    e.preventDefault();
    props.setter({ ...props.value, [e.target.name]: e.target.value });
  };

  const prefecture = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
    "日本国外",
  ];

  return (
    <>
      <Typography style={{ marginTop: "1rem" }}>都道府県*</Typography>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={6}>
          <Select
            variant="outlined"
            value={props.value.prefectures}
            fullWidth
            name="prefectures"
            onChange={handleSelect}
          >
            {prefecture.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </>
  );
};
