import React, { FC, useState, useContext, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Button,
  Divider,
  Select,
  MenuItem,
  Slider,
  Paper,
} from "@material-ui/core";
import { Store } from "../store";
import { makeStyles } from "@material-ui/core/styles";
import theme_app from "../Theme";
import { getStudents, groupingStudents } from "../utils/http-requests";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles({
  searchTitle: {
    marginTop: theme_app.spacing(3),
    color: "#616161",
  },
  continueText: {
    textAlign: "center",
    marginBottom: theme_app.spacing(2),
  },
  r_box: {
    display: "flex",
  },
  divide: {
    margin: theme_app.spacing(2, 0, 2),
  },
  submitButton: {
    margin: theme_app.spacing(1, 0, 2),
  },
  textGrey: {
    color: "#616161",
  },
  categoryText: {
    textAlign: "center",
    paddingTop: "1rem",
  },
  sliderText: {
    color: "#616161",
    textAlign: "center",
  },
  selectForm: {
    margin: theme_app.spacing(2, 0, 2),
  },
  cardType: {
    //margin: theme_app.spacing(4, 2),
    height: "100%",
    borderColor: grey[300],
    borderWidth: "3px",
  },
  userName: {
    padding: theme_app.spacing(0.5, 0),
  },
});

const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (v, k) => k + start);

const GroupPage: FC = () => {
  const classes = useStyles();
  const { state } = useContext(Store);
  const [groupNum, setGroupNum] = useState(1);
  const [groupWeight, setGroupWeight] = useState<number>(50);
  const [isSending, setIsSending] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [studentList, setStudentList] = useState<Array<Array<string>>>([[]]);
  const [studentNumber, setStudentNumber] = useState<number>(0);
  const [groupNumSelector, setGroupNumSelector] = useState<Array<number>>([1]);

  useEffect(() => {
    const f = async () => {
      const studentData = await getStudents();
      setStudentList(studentData.students);
      setStudentNumber(studentData.studentsNumber);
    };
    f();
  }, []);

  useEffect(() => {
    if (studentNumber === 0) {
      setIsShow(false);
    } else {
      const groupNumMax = Math.ceil(studentNumber / 2);
      setGroupNumSelector(range(1, groupNumMax));
      setIsShow(true);
    }
  }, [studentList, studentNumber]);

  const handleInputButton = async (e: React.FormEvent) => {
    if (isSending === false) {
      setIsSending(true);
      setIsShow(false);
      // バックエンド用にweightをリスケール
      const weight = groupWeight / 100;
      const students = await groupingStudents(groupNum, weight);
      setStudentList(students);
      setIsShow(true);
      setIsSending(false);
    } else {
      //setErrorAPI(true);
      //setLoading(false);
    }
  };

  const handleGroupNumberSelect = (e: any) => {
    e.preventDefault();
    setGroupNum(e.target.value);
  };
  const handleSlider = (event: any, newValue: number | number[]) => {
    setGroupWeight(newValue as number);
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <div className={classes.categoryText}>
          <Typography variant="h5" className={classes.textGrey}>
            グループ化ページ
          </Typography>
        </div>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={8}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              className={classes.selectForm}
            >
              <Grid item xs={3}>
                <Typography variant="h6" className={classes.textGrey}>
                  作成グループ数
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Select
                  variant="outlined"
                  value={groupNum}
                  fullWidth
                  name="stemInterest"
                  onChange={handleGroupNumberSelect}
                >
                  {groupNumSelector.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.selectForm}
          >
            <Grid item xs={3}>
              <Typography variant="body1" className={classes.sliderText}>
                ユーザー情報をもとにした
              </Typography>
              <Typography variant="h6" className={classes.sliderText}>
                「知識・技能」重視
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Slider
                value={groupWeight}
                valueLabelDisplay="auto"
                step={10}
                marks
                min={0}
                max={100}
                onChange={handleSlider}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" className={classes.sliderText}>
                視聴履歴をもとにした
              </Typography>
              <Typography variant="h6" className={classes.sliderText}>
                「興味・関心」重視
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              className={classes.submitButton}
              onClick={handleInputButton}
              disabled={isSending}
            >
              グループ化
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divide} />
        {isSending ? (
          <Grid container justify="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {isShow ? (
          <Grid container alignItems="stretch" justify="center" spacing={4}>
            {studentList.map((value, index) => (
              <Grid item xs={3} key={index}>
                <Paper
                  className={classes.cardType}
                  variant="outlined"
                  key={index}
                >
                  <Grid container justify="center" key={index}>
                    <Grid item key={index}>
                      <Typography variant="h6" className={classes.textGrey}>
                        グループ {index + 1}
                      </Typography>
                      {value.map((value, index) => (
                        <Typography key={index} className={classes.userName}>
                          {value}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default GroupPage;
