import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Store } from "../store";
import types from "../store/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    voidSpace: {
      paddingRight: "3rem",
    },
    appbar: {
      '@media print': {
        display: 'none'
     }
    }
  }),
);

const Appbar: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = useContext(Store);

  const handleNameClick = () => {
    history.push("/main");
  };

  return (
    <>
      <AppBar className={classes.appbar} position="sticky" elevation={0}>
        <Toolbar>
          {state.signInState === "SignIn" ||
          state.signInState === "EmailVerified" ? (
            <IconButton
              edge="start"
              className="test"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                dispatch({ type: types.SIDEBAR_CLICK });
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div className={classes.voidSpace} />
          )}
          <ButtonBase onClick={handleNameClick}>
            <Typography variant="h5" className="test2">
              Shuffle.
            </Typography>
          </ButtonBase>
          <div className={classes.grow} />
          {state.signInState === "SignIn" ? (
            <Typography variant="h6">{state.name} さん</Typography>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Appbar;
