import React, { FC, useContext, useEffect, useState, useRef } from "react";
import {
    Typography,
    Grid,
    Container,
    TextField,
    Paper,
    Box,
    Table,
    TableBody, 
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Link,
} from "@material-ui/core";
import {
    ScatterChart,
    CartesianGrid,
    XAxis,
    YAxis,
    ZAxis,
    Tooltip,
    Scatter,
    Legend,
    ResponsiveContainer,
  } from "recharts";
  import { DataGrid, GridColDef, jaJP } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

// self-modules
import { portfolioInformation } from "../utils/types";
import { Store } from "../store";
import {
    getReviews,
    getPortfolio,
  } from "../utils/http-requests";
import { scoreHistoryType, videoListData } from "../utils/types";
import VideoCard from "../components/common/videoCard"


const columns = [
    { field: "date", headerName: "日付", width: 100 },
    { field: "videoinformation", headerName: "動画情報", width: 400 },
    { field: "theme", headerName: "振り返り", width: 200 },
    { field: "description", headerName: "動画への感想", width: 200 },
  ];

interface VideoQueryData {
    dateFrom: Date;
    dateTo: Date;
    category: string;
  }

const theme = createMuiTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    jaJP
  );

const useStyles = makeStyles({
    textGrey: {
        color: "#616161",
      },
    firstTitle: {
        textAlign: "center",
        marginTop: "20px",
    },
    information: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
    },
    studyField: {
        location: "absolute",
        marginRight: "20px",
    },
    timePeriod: {
        location: "absolute",
        marginRight: "20px",
    },
    secondTitle: {
        textAlign: "center",
        marginTop: "100px",
    },
    chart: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        justifyItems: "center",
        breakAfter: "page",
    },
    thirdTitle: {
        textAlign: "center",
        marginTop: "100px",
    },
    textfield: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
        breakAfter: "page",
    },
    fourthTitle: {
        textAlign: "center",
        marginTop: "100px",
        marginBottom: "10px",
    },
    grid:{
        marginBottom: "30px",
        paddingBottom: "30px",
    },
    movePage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        margin: "2rem 0", // レイアウトの調整：各Containerと「ポートフォリオ一覧」リンクとの隙間を空ける
        color: "#616161",
        '@media print': {
            display: 'none'
        }
    },
    printNone: {
        '@media print': {
            display: 'none'
        }
    },
  });

function dateFormater (date:string) {
    const firstSplit = date.split("T")
    const secondSplit = firstSplit[1].split(":")
    return `${firstSplit[0]} ${secondSplit[0]}:${secondSplit[1]}`
}

const CustomTooltip: FC<any> = ({ active, payload }) => {
if (!active) {
    return null;
} else {
    const data = payload[0].payload;
    const date = dateFormater(data.saveAt)
    return (
    <>
        <Paper style={{ padding: "1rem" }}>
        <Grid container>
            <Grid item>
            <Typography style={{ color: "#616161" }}>{"単元名："}</Typography>
            </Grid>
            <Grid item>
            <Typography style={{ color: "#26ad77" }}>
                <Box fontWeight="fontWeightBold">
                    {data.title}
                </Box>
            </Typography>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item>
            <Typography style={{ color: "#616161" }}>
                {"粘り強く学習に取り組む態度："}
            </Typography>
            </Grid>
            <Grid item>
            <Typography style={{ color: "#26ad77" }}>
                <Box fontWeight="fontWeightBold">
                    {data.scoreContinueLearning}
                </Box>
            </Typography>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item>
            <Typography style={{ color: "#616161" }}>
                {"自ら学習を調整しようとする態度："}
            </Typography>
            </Grid>
            <Grid item>
            <Typography style={{ color: "#26ad77" }}>
                <Box fontWeight="fontWeightBold">
                    {data.scoreSelfLearning}
                </Box>
            </Typography>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item>
            <Typography style={{ color: "#616161" }}>{"更新日："}</Typography>
            </Grid>
            <Grid item>
            <Typography style={{ color: "#26ad77" }}>
                <Box fontWeight="fontWeightBold">
                    {date}
                </Box>
            </Typography>
            </Grid>
        </Grid>
        </Paper>
    </>
    );
}
return null;
};


const PortfolioProfilePage: FC = () => {
    const classes = useStyles();
    const { state } = useContext(Store);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [usageData, setUsageData] = useState<videoListData>({
        displayVideoList: []
    });
    const tableRef = useRef<HTMLTableSectionElement>(null);
    const history = useHistory();
    const [userId, setUserId] = useState("");
    const [title, setTitle] = useState("");
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [fullName, setFullName] = useState("");
    const [scoreHistory, setScoreHistory] = useState([] as Array<scoreHistoryType>);
    const [category, setCategory] = useState("");
    const [reviewText, setReviewText] = useState("");
    const [videoQuery, setVideoQuery] = useState<VideoQueryData>({
        dateFrom: new Date(),
        dateTo: new Date(),
        category: "",
      });

    useEffect(() => {
        const f = async () => {
            const writeSetPortfolioData = (data: any) => {
                setTitle(data.title);
                setDateFrom(data.dateFrom.split('T')[0]);
                setDateTo(data.dateTo.split('T')[0]); 
                setScoreHistory(data.scoreHistory);
                setCategory(data.category);
                setReviewText(data.review);
                setVideoQuery({
                    dateFrom: new Date(data.dateFrom),
                    dateTo: new Date(data.dateTo),
                    category: data.category,
                })
            };

            const qs = queryString.parse(history.location.search);
            if (qs.id && qs.d) {
                let { content, targetFullname } = await getPortfolio(String(qs.d), String(qs.id));
                writeSetPortfolioData(content)
                setFullName(targetFullname)
                setUserId(String(qs.id))
            } else if (qs.d) {
                let { content, targetFullname } = await getPortfolio(String(qs.d));
                writeSetPortfolioData(content)
                setFullName(targetFullname)
            }
        };
        f();
    },
    [videoQuery.category,]
    );


    useEffect(() => {
        setPage(0)
        getList()
      },
      [videoQuery.category, usageData.displayVideoList[usageData.displayVideoList.length - 1]?.title]
      );

    const getList = () => {
        const g = async () => {
            const qs = queryString.parse(history.location.search);
            if (videoQuery.dateFrom && videoQuery.dateTo && videoQuery.category) {
                const result = await getReviews(
                    videoQuery.dateFrom,
                    videoQuery.dateTo,
                    videoQuery.category,
                    qs.id ? String(qs.id) : undefined,
                    true,
                );
                setUsageData({
                    displayVideoList: result
                });
            }
        };
        g();
    };

    const moveUrl = (url: string): void => {
        history.push(url);
      };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
        tableRef?.current?.scrollIntoView(true)
      };
    
    const handleChangeRowsPerPage = (event: any) => {
        const value = parseInt(event.target.value, 10)
        setRowsPerPage(value);
        setPage(0);
        tableRef?.current?.scrollIntoView(true)
      };


    return(
        <>
            <Container maxWidth='lg' className={classes.textGrey}>
                <Typography  className={classes.firstTitle} variant="h5" >
                    学びのプロファイル
                </Typography>
                <div className={classes.information}>
                    <Typography  className={classes.studyField} variant="h6" >
                        単元名： {title}
                    </Typography>
                    <Typography  className={classes.timePeriod} variant="h6" >
                        期間： {dateFrom} 〜 {dateTo}
                    </Typography>
                    <Typography variant="h6" >
                        {fullName}
                    </Typography>
                </div>
            </Container>

            <Container maxWidth='lg' className={classes.textGrey}>
                <Typography  className={classes.secondTitle} variant="h5" >
                    「自己調整力」の自己評価の遷移図
                </Typography>
            </Container>

            <div className={classes.chart}>
                <ResponsiveContainer width={640} height={400} maxHeight={400} >
                    <ScatterChart margin={{ top: 50, bottom: 30 }}>
                        <CartesianGrid />
                        <XAxis
                        type="number"
                        dataKey="scoreContinueLearning"
                        name="粘り強く学習に取り組む態度"
                        label={{
                            value: "粘り強く学習に取り組む態度",
                            dy: 20,
                        }}
                        domain={[0, 100]}
                        />
                        <YAxis
                        type="number"
                        dataKey="scoreSelfLearning"
                        name="自ら学習を調整しようとする態度"
                        label={{
                            value: "自ら学習を調整しようとする態度",
                            angle: -90,
                            dx: -15,
                        }}
                        domain={[0, 100]}
                        />
                        <ZAxis range={[100, 100]} />
                        <Tooltip cursor={{ strokeDasharray: "3 3" }} content={CustomTooltip} />
                        <Legend
                        wrapperStyle={{
                            paddingTop: "2em",
                        }}
                        />
                        <Scatter 
                            name={`${category}`}
                            data={scoreHistory}
                            fill="#b2b500"
                            shape="circle"
                            legendType="circle"
                        />
                    </ScatterChart>
                </ResponsiveContainer>
            </div>

            <Container maxWidth='sm' className={classes.textGrey}>
                <Typography  className={classes.thirdTitle} variant="h5" >
                    単元を振り返って
                </Typography>
                <TextField fullWidth
                    className={classes.textfield}
                    value={reviewText}
                    variant="outlined"
                    multiline
                    rows={10}
                    disabled
                />
            </Container>

            <Link
            className={classes.movePage}
            onClick={() =>
                moveUrl(
                "/portfolio-list" + (userId ? "?id=" + userId : "")
                )
            }
            >
            ポートフォリオ一覧
            </Link>

            <Container maxWidth='lg' className={classes.textGrey}>
                <Typography  className={classes.fourthTitle} variant="h5" >
                    各回の振り返り／視聴動画／動画視聴感想等
                </Typography>
                <Paper style={{ width: '100%' }}>
                    <TableContainer style={{ height: '100%' }}>
                        <Table aria-label="custom pagination table" stickyHeader >
                            <TableHead>
                                <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                    key={column.field}
                                    style={{ minWidth: column.width, textAlign: "center"}}
                                    >
                                    {column.headerName}
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody ref={tableRef}>
                            {usageData.displayVideoList.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ width: 100 }} align="center">
                                        {value.saveAt?.split('T')[0]}
                                    </TableCell>
                                    <TableCell style={{ width: 400, padding: 0}}>
                                        <VideoCard {...value} key={index} backgroundColor="white" defaultThumbnail />
                                    </TableCell>
                                    <TableCell style={{ width: 200 }} align="left">
                                        {value?.recommendText}
                                    </TableCell>
                                    <TableCell style={{ width: 200 }} align="left">
                                        {value?.reviewText}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Table>
                    <TableFooter>
                        <TableRow>
                        <TablePagination
                            count={usageData.displayVideoList.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={"ページごとの件数"}
                            rowsPerPageOptions={[10, 25, 50]}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        </TableRow>
                    </TableFooter>
                    </Table>
                </Paper>
            </Container>

            <Link
            className={classes.movePage}
            onClick={() =>
                moveUrl(
                "/portfolio-list" + (userId ? "?id=" + userId : "")
                )
            }
            >
            ポートフォリオ一覧
            </Link>
        </>
        
    )

}

export default PortfolioProfilePage;

