import React, { useEffect, useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Backdrop, CircularProgress } from "@material-ui/core";

import { auth } from "../../utils/firebase";
import { Store } from "../../store/index";
import types from "../../store/types";

import { getUserData } from "../../utils/http-requests";

type Props = {
  children: React.ReactNode;
};

export const AuthCheck: React.FC = () => {
  const { state, dispatch } = useContext(Store);
  const location = useLocation()

  // ユーザ情報を取得してglobalに保存する関数
  const setLastTime = async () => {
    const data = await getUserData();
    dispatch({
      type: types.SET_LAST_TIME,
      payload: {
        lastRecommendTime: data.lastRecommendTime,
        lastUserInfoUpdateTime: new Date(data.lastUserInfoUpdateTime),
      },
    });
  };

  useEffect(() => {
    if (state.signInState === "SignIn") {
      setLastTime();
    }
  }, [state.signInState, location]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult(true).then((result) => {
          dispatch({
            type: types.SET_USER_FIREBASE,
            payload: {
              name: user.displayName || "",
              email: user.email || "",
              emailVerified: user.emailVerified || false,
              uid: user.uid || "",
              accountType: result.claims.type || "",
            },
          });
        });

        if (
          user.displayName !== null &&
          user.email !== "" &&
          user.emailVerified &&
          user.uid !== ""
        ) {
          dispatch({
            type: types.SET_SIGNIN_STATE,
            payload: {
              signInState: "SignIn",
            },
          });
        } else if (user.email !== "" && user.emailVerified && user.uid !== "") {
          dispatch({
            type: types.SET_SIGNIN_STATE,
            payload: {
              signInState: "EmailVerified",
            },
          });
        }
      } else {
        dispatch({
          type: types.SET_SIGNIN_STATE,
          payload: {
            signInState: "NotSignIn",
          },
        });
        // 最終ユーザ情報更新が「１カ月以内」のユーザAとそうでないユーザBとで
        // ログイン・ログアウトを繰り返したとき、
        // ユーザAのログイン時に設定画面に遷移されてしまう不具合を回避改修。
        dispatch({
          type: types.CLEAR_LAST_USER_INFO_UPDATE_TIME
        });
      }
    });
  }, [state.signInState]);

  useEffect(() => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("メールアドレスを入力してください");
      }
      if (email) {
        auth
          .signInWithEmailLink(email, window.location.href)
          .then((result) => {
            dispatch({
              type: types.SET_SIGNIN_STATE,
              payload: {
                signInState: "EmailVerified",
              },
            });
          })
          .catch((error) => {
            alert(error);
          });
      }
    }
  }, []);
  return <></>;
};

export default function Auth(props: Props): JSX.Element {
  const { state } = useContext(Store);

  if (state.signInState) {
    if (state.signInState === "SignIn") {
      return <>{props.children}</>;
    } else if (state.signInState === "EmailVerified") {
      return <Redirect from="*" to="/register" />;
    } else if (state.signInState === "NotSignIn") {
      return <Redirect from="*" to="/login" />;
    } else {
      return <p>Loading</p>;
    }
  } else {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}
