import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Paper,
  Select,
  MenuItem,
  ListSubheader,
  Button,
  Link,
  Box,
  CircularProgress,
  InputAdornment,
  IconButton,
  ButtonBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { blue, green } from "@material-ui/core/colors";

// self modules
import { auth } from "../utils/firebase";
import { Store } from "../store";
import types from "../store/types";
import theme_app from "../Theme";
import { FeedbackBar } from "../utils/feedback";
import { verifyToken, setUserData } from "../utils/http-requests";

// import exclusive modules
import {
  BirthSelector,
  DegreeSelector,
  PrefecturesSelector,
  registerInformation,
  ProgramInterestSelector,
  StemInterestSelector,
} from "../components/register/fields";

const useStyles = makeStyles({
  register_paper: {
    marginTop: theme_app.spacing(5),
    marginBottom: theme_app.spacing(5),
  },
  register_item: {
    marginTop: theme_app.spacing(2),
  },
  birth_item: {
    margin: theme_app.spacing(0, 1),
  },
  bg_color: {
    backgroundColor: "darkgray",
  },
  account_icon: {
    fontSize: theme_app.spacing(10),
    marginTop: theme_app.spacing(5),
    color: blue[200],
  },
  submit: {
    marginTop: theme_app.spacing(4),
    marginBottom: theme_app.spacing(4),
  },
  centering: { textAlign: "center" },
  inline: { display: "inline-block" },
  policy: {
    cursor: "pointer",
    paddingTop: theme_app.spacing(1),
    "&:hover": {
      "text-decoration": "underline",
    },
  },
  circle: { margin: theme_app.spacing(2) },
  circle_void: { margin: theme_app.spacing(6) },
});

const RegisterPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);
  const [isPassShow, setIsPassShow] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [inputData, setInputData] = useState({
    fullname: "",
    password: "",
    passwordConfirm: "",
    nickname: "",
    prefectures: "",
    birthYear: 2000,
    birthMonth: 1,
    birthDay: 1,
    degree: -1,
    stemInterest: -1,
    progInterest: -1,
    managementToken: "",
  } as registerInformation);
  const [inputDataValid, setInputDataValid] = useState({
    fullname: true,
    fullnameHelper: "",
    password: true,
    passwordHelper: "",
    passwordConfirm: true,
    nickname: true,
    nicknameHelper: "",
    managementToken: true,
    managementTokenHelper: "",
    degree: true,
    stemInterest: true,
    progInterest: true,
  });
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // if SignInState is "SignIn", move to main page
  useEffect(() => {
    if (state.signInState === "SignIn") {
      history.push("/main");
    } else if (state.signInState !== "EmailVerified") {
      history.push("/login");
    }
  }, [state.signInState]);

  // input handle functions
  const handleFormInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  const handleBlurValid = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    switch (e.target.name) {
      case "fullname":
        if (inputData.fullname.length === 0) {
          setInputDataValid({
            ...inputDataValid,
            fullname: false,
            fullnameHelper: "氏名を入力してください。",
          });
        } else if (inputData.fullname.length > 20) {
          setInputDataValid({
            ...inputDataValid,
            fullname: false,
            fullnameHelper: "氏名が長すぎます。",
          });
        } else {
          setInputDataValid({
            ...inputDataValid,
            fullname: true,
            fullnameHelper: "",
          });
        }
        break;
      case "nickname":
        if (inputData.nickname.length === 0) {
          setInputDataValid({
            ...inputDataValid,
            nickname: false,
            nicknameHelper: "ニックネームを入力してください。",
          });
        } else if (inputData.nickname.length > 20) {
          setInputDataValid({
            ...inputDataValid,
            nickname: false,
            nicknameHelper: "ニックネームが長すぎます。",
          });
        } else {
          setInputDataValid({
            ...inputDataValid,
            nickname: true,
            nicknameHelper: "",
          });
        }
        break;
      case "password":
        if (inputData.password.length < 6) {
          setInputDataValid({
            ...inputDataValid,
            password: false,
            passwordHelper: "パスワードが短すぎます。",
          });
        } else if (inputData.password.length > 30) {
          setInputDataValid({
            ...inputDataValid,
            password: false,
            passwordHelper: "パスワードが長すぎます。",
          });
        } else {
          setInputDataValid({
            ...inputDataValid,
            password: true,
            passwordHelper: "",
          });
        }
        break;
      case "passwordConfirm":
        setInputDataValid({
          ...inputDataValid,
          passwordConfirm: inputData.password === inputData.passwordConfirm,
        });
        break;
      case "managementToken":
        if (inputData.managementToken.length < 6) {
          setInputDataValid({
            ...inputDataValid,
            managementToken: false,
            managementTokenHelper: "管理トークンが短すぎます",
          });
        } else if (inputData.managementToken.length > 128) {
          setInputDataValid({
            ...inputDataValid,
            managementToken: false,
            managementTokenHelper: "管理トークンが長すぎます",
          });
        } else {
          setInputDataValid({
            ...inputDataValid,
            managementToken: true,
            managementTokenHelper: "",
          });
        }

        break;
    }
  };
  const createAccount = async () => {
    try {
      const doAuth = async () => {
        return new Promise<any>((resolve, reject) => {
          auth.onAuthStateChanged((user) => {
            resolve(user);
          });
        });
      };
      const authUser = await doAuth();
      await authUser.updatePassword(inputData.password);
      await authUser?.updateProfile({
        displayName: inputData.nickname,
      });
    } catch (error) {
      if (error.code === "auth/requires-recent-login") {
        throw new Error("RequireReLogin");
      } else {
        throw new Error("FirebaseFailed");
      }
    }
  };

  const handleSendButton = async () => {
    setIsSending(true);
    if (
      inputDataValid.fullname &&
      inputDataValid.nickname &&
      inputDataValid.password &&
      inputDataValid.passwordConfirm &&
      inputData.fullname !== "" &&
      inputData.nickname !== "" &&
      inputData.password !== "" &&
      inputData.passwordConfirm !== "" &&
      inputData.degree &&
      inputData.degree !== -1 &&
      inputData.stemInterest !== -1 &&
      inputData.progInterest !== -1 &&
      inputData.prefectures !== ""
    ) {
      try {
        await verifyToken(inputData.managementToken);
        await createAccount();
        await setUserData(
          inputData.managementToken,
          inputData.fullname,
          inputData.nickname,
          inputData.birthYear.toString() +
          "-" +
          inputData.birthMonth.toString() +
          "-" +
          inputData.birthDay.toString(),
          inputData.degree,
          inputData.stemInterest,
          inputData.progInterest,
          inputData.prefectures
        );
        dispatch({
          type: types.SET_SIGNIN_STATE,
          payload: {
            signInState: "",
          },
        });
        setFeedbackInfo({
          ...feedbackInfo,
          open: true,
          text: "ユーザ登録が完了しました",
          type: "success",
        });
      } catch (error) {
        let errText = "";
        if (error.message === "verifyTokenFailed") {
          errText =
            "管理トークンが正しくありません。もう一度管理トークンをご確認ください。";
        } else if (error.message === "FirebaseFailed") {
          errText =
            "ユーザ情報を登録できませんでした。もう一度登録をやり直してください。";
        } else if (error.message === "RequireReLogin") {
          errText =
            "メールを送信してから時間が経過しすぎたため登録できませんでした。左のメニューから『ログアウト』を押し、再度メールを送り、最初から登録を行ってください。";
        } else {
          errText =
            "ユーザ情報を登録できませんでした。時間をおいて、再度登録をやり直してください。";
        }
        setFeedbackInfo({
          ...feedbackInfo,
          open: true,
          text: errText,
          type: "error",
        });
      }
    } else {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "ユーザ情報を登録できませんでした。入力が正しいか、全て入力しているか、ご確認ください。",
        type: "warning",
      });
    }
    setIsSending(false);
  };
  const handleClickShowPassword = () => {
    setIsPassShow(!isPassShow);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <Paper elevation={3} className={classes.register_paper}>
          <div className={classes.centering}>
            <AccountBoxIcon className={classes.account_icon} />
            <Typography variant="h6">アカウント登録</Typography>
          </div>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={6}>
              <div className={classes.register_item}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="メールアドレス"
                  autoComplete="email"
                  name="email"
                  value={state.email}
                  disabled={true}
                />
                <TextField
                  error={!inputDataValid.fullname}
                  helperText={inputDataValid.fullnameHelper}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="氏名"
                  autoComplete="fullname"
                  name="fullname"
                  value={inputData.fullname}
                  onChange={handleFormInput}
                  onBlur={handleBlurValid}
                />
                <TextField
                  error={!inputDataValid.nickname}
                  helperText={inputDataValid.nicknameHelper}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="nickname"
                  label="ニックネーム"
                  autoComplete="nickname"
                  name="nickname"
                  value={inputData.nickname}
                  onChange={handleFormInput}
                  onBlur={handleBlurValid}
                />
                <TextField
                  error={!inputDataValid.password}
                  helperText={
                    !inputDataValid.password
                      ? inputDataValid.passwordHelper
                      : ""
                  }
                  variant="outlined"
                  margin="normal"
                  required
                  type={isPassShow ? "text" : "password"}
                  fullWidth
                  id="password"
                  label="パスワード(６文字以上)"
                  autoComplete="password"
                  name="password"
                  value={inputData.password}
                  onChange={handleFormInput}
                  onBlur={handleBlurValid}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {isPassShow ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  error={!inputDataValid.passwordConfirm}
                  helperText={
                    !inputDataValid.passwordConfirm
                      ? "上のパスワードと違います。"
                      : ""
                  }
                  variant="outlined"
                  margin="normal"
                  required
                  type={isPassShow ? "text" : "password"}
                  fullWidth
                  id="passwordConfirm"
                  label="パスワード（確認用）"
                  autoComplete="password"
                  name="passwordConfirm"
                  value={inputData.passwordConfirm}
                  onChange={handleFormInput}
                  onBlur={handleBlurValid}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {isPassShow ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={6}>
              <BirthSelector value={inputData} setter={setInputData} />
              <PrefecturesSelector value={inputData} setter={setInputData} />
              <DegreeSelector value={inputData} setter={setInputData} />
              <StemInterestSelector value={inputData} setter={setInputData} />
              <ProgramInterestSelector
                value={inputData}
                setter={setInputData}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={6}>
              <div className={classes.register_item}>
                <TextField
                  error={!inputDataValid.managementToken}
                  helperText={inputDataValid.managementTokenHelper}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="managementToekn"
                  label="管理トークン"
                  autoComplete="token"
                  name="managementToken"
                  value={inputData.managementToken}
                  onChange={handleFormInput}
                  onBlur={handleBlurValid}
                />
              </div>
              <div className={classes.centering}>
                <div className={classes.register_item}>
                  <Box fontWeight="bold" color={blue[400]}>
                    <div className={classes.policy}>
                      <ButtonBase
                        onClick={() =>
                          window.open(
                            process.env.REACT_APP_SERVER_ADDRESS +
                            "/term_of_service"
                          )
                        }
                      >
                        <Typography>シャッフルテン 利用規約</Typography>
                      </ButtonBase>
                    </div>
                  </Box>
                  <Box fontWeight="bold" color={blue[400]}>
                    <div className={classes.policy}>
                      <ButtonBase
                        onClick={() =>
                          window.open(
                            process.env.REACT_APP_SERVER_ADDRESS +
                            "/privacy_policy"
                          )
                        }
                      >
                        <Typography>
                          シャッフルテン プライバシーポリシー
                        </Typography>
                      </ButtonBase>
                    </div>
                  </Box>
                  <Box fontWeight="bold" color={blue[400]}>
                    <div className={classes.policy}>
                      <ButtonBase
                        onClick={() => {
                          window.open("https://www.youtube.com/t/terms");
                        }}
                      >
                        <Typography>Youtube 利用規約</Typography>
                      </ButtonBase>
                    </div>
                  </Box>

                  <Button
                    className={classes.submit}
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSendButton}
                  >
                    上記利用規約とプライバシーポリシーに同意して登録
                  </Button>
                  {isSending ? (
                    <CircularProgress className={classes.circle} size={60} />
                  ) : (
                    <div className={classes.circle_void}></div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <FeedbackBar {...feedbackInfo} handleClose={handleClose} />
    </>
  );
};

export default RegisterPage;
