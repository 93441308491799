// ポートフォリオの一覧を表示するページ
import React, { FC, useContext, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Paper,
  Button,
  List,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { blue } from "@material-ui/core/colors";
import queryString from "query-string";

// self-modules
import { Store } from "../store";
import PortfolioGraph from "../components/portfolio/scatter-graph";
import PortfolioCardList, {
  PortfolioCardProps,
} from "../components/portfolio/portfolio-card";
import { getPortfolioList } from "../utils/http-requests";

const useStyles = makeStyles({
  centering: {
    textAlign: "center",
  },
  pageTitle: {
    textAlign: "center",
    paddingTop: "2em",
    paddingBottom: "1em",
  },
  textGrey: {
    color: "#616161",
  },
  scrollable: {
    overflowY: "scroll",
    maxHeight: "35rem",
    minHeight: "35rem",
    borderColor: blue[200],
    borderWidth: "3px",
    backgroundColor: "#fafafa",
  },
  createButton: {
    margin: "1em",
  },
  portfolioGraph: {
    margin: "3em",
  },
});

const PortfolioListPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useContext(Store);
  const [portfoioList, setPortfoioList] = useState(
    [] as Array<PortfolioCardProps>
  );
  const [userId, setUserId] = useState("");
  const [studentFullname, setStudentFullname] = useState("");

  // 生徒のidをURLパラメータから取得
  useEffect(() => {
    const f = async () => {
      const qs = queryString.parse(history.location.search);
      // idがあればそのユーザの一覧を取得
      const { content, targetFullname } = await getPortfolioList(
        String(qs.id || "")
      );
      setUserId(String(qs.id || ""));
      setPortfoioList(content);
      setStudentFullname(targetFullname);
    };
    f();
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.pageTitle}>
          <Typography variant="h5" className={classes.textGrey}>
            {`${studentFullname !== "" ? studentFullname + " さんの" : ""}`}
            ポートフォリオ一覧
          </Typography>
        </div>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={7} lg={7}>
            <PortfolioGraph data={portfoioList} />
          </Grid>
          <Grid item xs={8} md={5} lg={5}>
            <div className={classes.centering}>
              <Button
                variant="contained"
                color="primary"
                className={classes.createButton}
                onClick={() => {
                  history.push("/portfolio");
                }}
                disabled={studentFullname !== ""}
              >
                <Typography>新規作成</Typography>
              </Button>
            </div>
            <Paper className={classes.scrollable} variant="outlined" square>
              <PortfolioCardList data={portfoioList} userId={userId} />
              {portfoioList.length === 0 ? (
                <div className={classes.centering}>
                  <Typography
                    variant="h6"
                    className={classes.textGrey}
                    style={{ marginTop: "1rem" }}
                  >
                    ポートフォリオがありません
                  </Typography>
                </div>
              ) : (
                <></>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PortfolioListPage;
