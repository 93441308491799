import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Card, ButtonBase, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { datetoString } from "../common/datetime";

const useStyles = makeStyles({
  centering: {
    textAlign: "center",
  },
  cardSpace: {
    margin: "1em",
    display: "flex"
  },
  portfolioCard: {
    padding: "1em",
    width: "160%",
    maxHeight: "220px",
  },
  textGrey: {
    textAlign: "center",
    color: "#616161",
  },
  textTitleGrey : {
    textAlign: "center",
    color: "#616161",
    fontWeight: 600,
  },
  buttons: {
    marginTop: "10px",
    width: "100%"
  },
  buttonContainer: {
    display: "grid",
    marginLeft: "10px",
  }
});

export interface PortfolioCardRaw {
  title: string;
  saveAt: string;
  category: string;
  scoreContinueLearning: number;
  scoreSelfLearning: number;
  portfolioId: string;
}

export const portfolioCardParser = (
  props: PortfolioCardRaw
): PortfolioCardProps => {
  const parsed = {
    title: props.title,
    saveAt: new Date(props.saveAt),
    category: props.category,
    scoreContinueLearning: props.scoreContinueLearning,
    scoreSelfLearning: props.scoreSelfLearning,
    portfolioId: props.portfolioId,
  } as PortfolioCardProps;
  return parsed;
};

export interface PortfolioCardProps {
  title: string;
  saveAt: Date;
  category: string;
  scoreContinueLearning: number;
  scoreSelfLearning: number;
  portfolioId: string;
  userId?: string;
}

export interface PortfolioCardListProps {
  data: Array<PortfolioCardProps>;
  userId?: string;
}

// ポートフォリオ画面におけるポートフォリオカードのリスト
const PortfolioCardList: FC<PortfolioCardListProps> = (props) => {
  return (
    <>
      {props.data.map((value, index) => (
        <PortfolioCard key={index} {...value} userId={props.userId} />
      ))}
    </>
  );
};

// ポートフォリオ画面におけるポートフォリオカード
const PortfolioCard: FC<PortfolioCardProps> = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <>
      <div className={classes.cardSpace}>
        <Card className={classes.portfolioCard} style={{backgroundColor: isHovered ? "#F1F1F1" : "transparent"}}>
          <Typography variant="h6" className={classes.textTitleGrey}>
            {props.title}
          </Typography>
          <Grid container justify="space-evenly">
            <Grid item xs={10}>
              <Typography variant="body2" className={classes.textGrey}>
                カテゴリ: {props.category}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2" className={classes.textGrey}>
                作成日:
                {datetoString(props.saveAt)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Grid container justify="center" className={classes.buttonContainer} >
          <Grid item xs={12} >
            <Button 
            className={classes.buttons}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(
                `/portfolio?${props.userId ? "id=" + props.userId + "&" : ""}d=${
                  props.portfolioId
                }`
              );
            }}>
              振り返り入力
            </Button>
          </Grid>
          <Grid item xs={12} >
            <Button 
            className={classes.buttons}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(
                `/portfolio-profile?${props.userId ? "id=" + props.userId + "&" : ""}d=${
                  props.portfolioId
                }`
              );
            }}>
              プロファイル確認
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PortfolioCardList;