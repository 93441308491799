import React, { FC, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme_app from "../Theme";

const useStyles = makeStyles({
  textBox: {
    textAlign: "center",
    color: "#616161",
    paddingBottom: theme_app.spacing(2),
    "&:hover": {
      "text-decoration": "underline",
    },
  },
});

const FooterContent: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={2}>
          <div className={classes.textBox}>
            <ButtonBase
              onClick={() =>
                window.open(
                  process.env.REACT_APP_SERVER_ADDRESS + "/term_of_service"
                )
              }
            >
              <Typography>Shuffle．利用規約</Typography>
            </ButtonBase>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.textBox}>
            <ButtonBase
              onClick={() =>
                window.open(
                  process.env.REACT_APP_SERVER_ADDRESS + "/privacy_policy"
                )
              }
            >
              <Typography>プライバシーポリシー</Typography>
            </ButtonBase>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.textBox}>
            <ButtonBase
              onClick={() => {
                window.open("https://www.youtube.com/t/terms");
              }}
            >
              <Typography>Youtube利用規約</Typography>
            </ButtonBase>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default FooterContent;
