import React, { FC } from "react";
import { Typography, Container, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme_app from "../Theme";

const useStyles = makeStyles({
  textGrey: {
    color: "#616161",
  },
  title: {
    padding: theme_app.spacing(3),
    color: "#616161",
  },
});

const TermofServicePage: FC = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="md">
        <Typography variant="h4" className={classes.title}>
          利用規約
        </Typography>
        <Typography variant="body1" className={classes.textGrey}>
          本利用規約（以下，「本規約」といいます。）は，合同会社MAZDA Incredible
          Lab（以下，「当社」といいます。）がこのウェブサイト上で提供するAIリコメンドサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
          <br />
          <br />
          第１条（サービス内容）
          <br />
          　本サービスは、当社が提供するAIリコメンドシステム「Shuffle.（シャッフル・テン）」です。本サービスのサービス内容の詳細は、本利用規約のほか、当社が別途書面やウェブサイトに定める通りとします。
          　本サービスはYouTubeAPIサービスを利用しており、ユーザーはYouTubeの利用規約(以下、リンク参照)に同意したものとして本サービスを利用することになります。
          <Link href="https://www.youtube.com/t/terms">
            https://www.youtube.com/t/terms
          </Link>
          <br />
          <br />
          第２条（定義）
          <br />
          　本利用規約においては，以下の用語は，特段の定義がある場合および文脈上別異に解すべき場合を除き，それぞれ以下に定める意味を有するものとします。
          <br />
          　１　「個別規程」とは，本サービスに関して，本利用規約とは別に，申込書，ガイドライン，ポリシー，規程，規約等の名称で当社がウェブサイト上に掲示またはユーザーに配布する文書をいいます。
          <br />
          　２　「コンテンツ」とは，文章，音声，音楽，画像，動画，データ，プログラムその他の情報をいい，本サービスを通じてアクセスすることができるコンテンツを「サービスコンテンツ」，ユーザーが本サービスを利用して送信，保存等したコンテンツを「ユーザーコンテンツ」とそれぞれいいます。
          <br />
          　３　「知的財産権」とは，著作権，特許権，実用新案権，商標権，意匠権その他の知的財産権をいい，それらの権利を取得しまたはそれらの権利につき登録等を出願する権利を含みます。
          <br />
          　４　「ユーザー」とは，本条に定める契約者及び個別利用者を個別に又は総称したものをいいます。
          <br />
          　５　「契約者」とは，本利用規約に同意の上，当社所定の手続に従って本サービスの利用を申し込み当社の承諾を受けた法人（以下の各号の者を含むがこれに限られない。），その他の団体および個人をいいます。
          <br />
          　　（１）教育委員会
          <br />
          　　（２）学校（学校教育法に定める「学校」及びそれに準じる認定在外教育施設など）を管理する地方自治体
          <br />
          　　（３）民間教育事業者（「学校」以外の学習塾，コンサルティング事業者，研修事業者など）
          <br />
          　　（４） 前号に定めるもの以外の会社
          <br />
          　６　「利用者」とは，利用団体の管理下において，本サービスを利用する児童，生徒，保護者及び利用団体の教職員をいいます。
          <br />
          <br />
          第３条（適用）
          <br />
          　本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
          <br />
          　１　当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
          <br />
          　２　本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
          <br />
          <br />
          第４条（利用登録）
          <br />
          本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
          <br />
          　１　利用登録の申請に際して虚偽の事項を届け出た場合
          <br />
          　２　本規約に違反したことがある者からの申請である場合
          <br />
          　３　その他，当社が利用登録を相当でないと判断した場合
          <br />
          <br />
          第５条（無償試用)
          <br />
          　ユーザーは、別途当社が定める期間および範囲内において、本利用規約に従って本サービスを無償でご試用いただくことができます。本サービスの無償試用についても本サービスの利用に含まれるものとして本利用規約の規定が適用されます。
          <br />
          <br />
          第６条（ユーザーIDおよびパスワードの管理）
          <br />
          　１　ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
          <br />
          　２　ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
          <br />
          　３　ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。
          <br />
          <br />
          第７条（利用料金および支払い方法）
          <br />
          　１　本サービスを有償利用される場合，当社は，契約者に許諾したサービス内容，デバイス数，個別利用者数，利用期間等によって利用料金を設定いたします。契約者は，ご利用のサービス内容，デバイス数，個別利用者数，利用期間等に応じて，該当の利用料金を個別に定める支払期日までに当社所定の方法により当社に支払うものとします。本サービスの利用料金の詳細につきましては、当社が別途定める価格表に従うものとします。また，本利用規約に別段の定めがある場合を除き，当社は既に支払われた利用料金の返金等を行ないません。
          <br />
          　２　契約者の利用料金その他の当社に対する債務について支払期日を経過してもなお支払いがない場合には，支払期日の翌日から起算して支払いの日の前日までの期間について，年10.0%の割合での日割計算（なお閏年の日を含む場合も1年365日として計算するものとします。）による遅延損害金を当該契約者に当社にお支払いただく場合があります。
          <br />
          <br />
          第８条（禁止事項）
          <br />
          　ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
          <br />
          　１　法令または公序良俗に違反する行為
          <br />
          　２　犯罪行為に関連する行為
          <br />
          　３　本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
          <br />
          　４　当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
          <br />
          　５　本サービスによって得られた情報を商業的に利用する行為
          <br />
          　６　当社のサービスの運営を妨害するおそれのある行為
          <br />
          　７　不正アクセスをし，またはこれを試みる行為
          <br />
          　８　他のユーザーに関する個人情報等を収集または蓄積する行為
          <br />
          　９　不正な目的を持って本サービスを利用する行為
          <br />
          　10　本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
          <br />
          　11　他のユーザーに成りすます行為
          <br />
          　12　当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
          <br />
          　13　面識のない異性との出会いを目的とした行為
          <br />
          　14　当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
          <br />
          　15　その他，当社が不適切と判断する行為
          <br />
          <br />
          第９条（本サービスの提供の停止等）
          <br />
          　当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
          <br />
          　１　本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
          <br />
          　２　地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
          <br />
          　３　コンピュータまたは通信回線等が事故により停止した場合
          <br />
          　４　その他，当社が本サービスの提供が困難と判断した場合
          <br />
          　当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
          <br />
          <br />
          第10条（利用制限および登録抹消）
          <br />
          　当社は、ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。
          <br />
          　１　本規約のいずれかの条項に違反した場合
          <br />
          　２　登録事項に虚偽の事実があることが判明した場合
          <br />
          　３　料金等の支払債務の不履行があった場合
          <br />
          　４　当社からの連絡に対し，一定期間返答がない場合
          <br />
          　５　本サービスについて，最終の利用から一定期間利用がない場合
          <br />
          　６　その他，当社が本サービスの利用を適当でないと判断した場合
          <br />
          　当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
          <br />
          <br />
          第11条（退会）
          <br />
          　ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。
          　ユーザーは、利用終了または個別利用者数変更希望月の前月末日の10営業日前までに当社指定の方法で，当社に通知するものとします。当社は，通知いただいた月の翌月で当該ユーザーの有償利用を終了しまたは翌月から変更後の内容に基づく利用料金を適用するものとします。
          <br />
          <br />
          第12条（保証の否認および免責事項）
          <br />
          　当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
          当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
          前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。
          また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
          当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
          <br />
          <br />
          第13条（サービス内容の変更等）
          <br />
          　当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
          <br />
          <br />
          第14条（コンテンツおよび知的財産権等の帰属等）
          <br />
          　本サービスおよびサービスコンテンツに関する知的財産権その他の権利はすべて当社（または当社に利用許諾，実施許諾もしくは使用許諾（以下総称して「使用許諾」といいます。）している者）に帰属しており，本利用規約に基づく本サービスの利用の許諾は，本サービスに関する当社（または当社に使用許諾している者）の知的財産権の使用許諾を意味するものではありません。
          当社は，ユーザーコンテンツを当社のサービスやプロモーション等のために地域を問わず無償かつ無期限で利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。以下同じ。）することができるものとします。
          ユーザーは，本項に基づく当社によるユーザーコンテンツの利用に関し，著作者人格権を行使しないものとします。なお，本規定は本利用規約の終了後も効力を有するものとします。
          <br />
          <br />
          第15条（ユーザーコンテンツの取扱い）
          <br />
          　契約者および個別利用者が本サービスに保存したすべてのユーザーコンテンツ（以下，「保存データ」といいます。）は契約者および個別利用者自身により管理されるものであり，本利用規約に基づき許諾される範囲を除き，保存データに関する権利は契約者および個別利用者に帰属します。
          当社は，利用期間が終了した場合には，当社が別途決定する保管期間の経過後，保存データを削除します。
          当社は，利用期間終了後は，保存データについて，その保管，削除，バックアップ等に関して契約者，個別利用者および第三者に生じた損害につき，別段の定めのない限り，責任を負いません（ただし，利用期間内も当社の責任は本利用規約に定める範囲に限定されます。）。
          <br />
          　当社は，以下の目的によると当社が判断した場合，保存データにアクセスすることができるものとします。
          　１　本サービスに関するシステムの安全な運営のため
          <br />
          　２　本サービスまたは本サービスのシステム上の問題を解決または防止するため
          <br />
          　３　本サービスのサポート上の問題に関連してユーザーから当社に要請があった場合に、当該サポート上の問題を解決するため
          <br />
          　４　法令または本利用規約の遵守状況などを確認するため
          <br />
          　当社は，別段の定めのない限り，本条に基づき当社が行った措置に基づきユーザーに生じた損害について責任を負いません。
          <br />
          <br />
          第16条（本サービスの利用およびユーザーの責任）
          <br />
          　ユーザーは，本サービスに有効に利用登録されている期間内に限り，本利用規約および当社の定めるところにしたがい，本サービスを利用することができます。ユーザーの本サービスのご利用にあたり，当社は当社のサービスやプロモーション等に関し，ユーザーに対し協力をお願いさせていただくことがあります。
          ユーザーは，自らの責任において本サービスを利用するものとし，本サービスにおいて行った行為およびその結果について責任を負うものとします。
          本サービスに関連してユーザー間又はユーザーと第三者との間において生じた紛争等については，ユーザーの責任において解決するものとし、当社はかかる事項について、別段の定めのない限り、責任を負いません。ユーザーは，当社に対し，かかる紛争等について請求を行わないものとします。
          ユーザーは，本サービスを利用したことに関連して，当社が直接的もしくは間接的に何らかの損害（弁護士費用の負担を含みます。）を被った場合（当社がユーザーの利用を原因とする請求等を第三者より受けた場合を含みます。），当社の請求に従って直ちにこれを補償するものとします。
          <br />
          <br />
          第17条（利用規約の変更）
          <br />
          　当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
          <br />
          <br />
          第18条（個人情報の取扱い）
          <br />
          　当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。
          <br />
          　本サービスはYouTubeAPIサービスを使用しており、ユーザーはGoogleプライバシーポリシー(以下、リンク参照)に同意したものとして、本サービスを利用することとなります。
          <br />
          <Link href="http://www.google.com/policies/privacy">
            http://www.google.com/policies/privacy
          </Link>
          <br />
          <br />
          第19条（通知または連絡）
          <br />
          　ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。
          <br />
          　当社は，ユーザーから，当社が別途定める方式に従った変更届け出がない限り，現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い，これらは，発信時にユーザーへ到達したものとみなします。
          <br />
          <br />
          第20条（権利義務の譲渡の禁止）
          <br />
          　ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
          <br />
          <br />
          第21条（準拠法・裁判管轄）
          <br />
          　本規約の解釈にあたっては，日本法を準拠法とします。
          <br />
          　本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
          <br />
          <br />
          <br />
          2021年4月3日制定
          <br />
          <br />
        </Typography>
      </Container>
    </>
  );
};

export default TermofServicePage;
