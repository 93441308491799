import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import {
  Typography,
  Card,
  Grid,
  ButtonBase,
  Link,
  Collapse,
  Button,
} from "@material-ui/core";
import theme_app from "../../Theme";

export interface VideoCardDetailProps {
  title: string;
  channelTitle: string;
  videoId: string;
  backgroundColor?: string;
  recommendText?: string;
  reviewText?: string;
  viewTime?: number;
  saveAt?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    cardstyle: {
      margin: theme_app.spacing(2),
      backgroundColor: grey[50],
    },
    cardstyleWhite: {
      margin: theme_app.spacing(2),
      backgroundColor: "ffffff",
    },
    vid_title: {
      cursor: "pointer",
      marginTop: theme.spacing(1),
    },
    centering: {
      textAlign: "center",
    },
    textGrey: {
      color: "#616161",
    },
    textBlue: {
      color: blue[200],
    },
  })
);

const VideoCardDetail: FC<VideoCardDetailProps> = (props) => {
  const classes = useStyles();
  const [isDetail, setIsDetail] = useState(false);
  const youtubeURL = () => {
    window.open("/video?v=" + props.videoId);
  };
  const [imageUrl, setImageUrl] = useState(
    `https://i.ytimg.com/vi/${props.videoId}/maxresdefault.jpg`
  );
  const img = new Image();
  img.src = `https://i.ytimg.com/vi/${props.videoId}/maxresdefault.jpg`;
  img.onload = (e: any) => {
    if (img.width === 120 && img.height === 90) {
      setImageUrl(`https://i.ytimg.com/vi/${props.videoId}/mqdefault.jpg`);
    } else {
      setImageUrl(`https://i.ytimg.com/vi/${props.videoId}/maxresdefault.jpg`);
    }
  };

  return (
    <Card
      variant="outlined"
      className={
        props.backgroundColor ? classes.cardstyleWhite : classes.cardstyle
      }
    >
      <Grid container alignItems="center" justify="center">
        <Grid item xs={8} sm={5}>
          <ButtonBase className={classes.image} onClick={youtubeURL}>
            <img className={classes.image} alt="complex" src={imageUrl} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm={7}>
          <div className={classes.centering}>
            <Typography className={classes.vid_title} gutterBottom>
              <Link onClick={youtubeURL} color="inherit">
                {props.title}
              </Link>
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              {props.channelTitle}
            </Typography>
          </div>
          <div className={classes.centering}>
            <ButtonBase
              onClick={() => {
                setIsDetail(!isDetail);
              }}
            >
              <Typography className={classes.textBlue}>詳細</Typography>
            </ButtonBase>
          </div>
        </Grid>
      </Grid>
      <Collapse in={isDetail}>
        <Grid container justify="space-evenly">
          <Grid item xs={10}>
            <div className={classes.centering}>
              {props.recommendText ? (
                <Typography
                  className={classes.textGrey}
                  style={{ paddingTop: "1rem" }}
                  variant="h6"
                >
                  振り返り
                </Typography>
              ) : (
                <></>
              )}
            </div>
            <Typography>{props.recommendText}</Typography>
          </Grid>
          <Grid item xs={10}>
            <div className={classes.centering}>
              {props.reviewText ? (
                <Typography
                  className={classes.textGrey}
                  style={{ paddingTop: "1rem" }}
                  variant="h6"
                >
                  動画への感想
                </Typography>
              ) : (
                <></>
              )}
            </div>
            <Typography>{props.reviewText}</Typography>
          </Grid>
        </Grid>
      </Collapse>
    </Card>
  );
};

export default VideoCardDetail;
