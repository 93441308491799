import { blue } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const theme_app = createMuiTheme({
  palette: {
    primary: blue,
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      "Arial",
      '"Hiragino Kaku Gothic ProN"',
      '"Hiragino Sans"',
      '"BIZ UDPGothic"',
      "Meiryo",
      "sans-serif",
    ].join(","),
  },
});

export default theme_app;
