// レコメンド時に使用するカテゴリのセレクター
import React, { FC } from "react";
import { Menu, MenuItem } from "@material-ui/core";
//import NestedMenuItem from "material-ui-nested-menu-item";
import NestedMenuItem from "./nested-menu-item";

interface categoryNestedPulldownProps {
  anchor: Element | null;
  setAnchor: (item: HTMLElement | null) => void;
  setCategory: (item: string) => void;
  left: boolean;
  addOthers?: boolean;
}

const CategoryNestedPulldown: FC<categoryNestedPulldownProps> = (props) => {
  const handleCategoryClose = () => {
    props.setAnchor(null);
  };
  return (
    <>
      <Menu
        anchorEl={props.anchor}
        keepMounted
        open={Boolean(props.anchor)}
        onClose={handleCategoryClose}
      >
        <NestedMenuItem
          label="算数/数学"
          mainMenuOpen={Boolean(props.anchor)}
          left={props.left}
        >
          <NestedMenuItem
            label="小学校"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("算数/小学校/1年");
                handleCategoryClose();
              }}
            >
              1年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("算数/小学校/2年");
                handleCategoryClose();
              }}
            >
              2年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("算数/小学校/3年");
                handleCategoryClose();
              }}
            >
              3年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("算数/小学校/4年");
                handleCategoryClose();
              }}
            >
              4年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("算数/小学校/5年");
                handleCategoryClose();
              }}
            >
              5年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("算数/小学校/6年");
                handleCategoryClose();
              }}
            >
              6年
            </MenuItem>
          </NestedMenuItem>
          <NestedMenuItem
            label="中学校"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("数学/中学校/1年");
                handleCategoryClose();
              }}
            >
              1年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("数学/中学校/2年");
                handleCategoryClose();
              }}
            >
              2年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("数学/中学校/3年");
                handleCategoryClose();
              }}
            >
              3年
            </MenuItem>
          </NestedMenuItem>
          <NestedMenuItem
            label="高等学校"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("数学 Ⅰ");
                handleCategoryClose();
              }}
            >
              数学 Ⅰ
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("数学 Ⅱ");
                handleCategoryClose();
              }}
            >
              数学 Ⅱ
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("数学 Ⅲ");
                handleCategoryClose();
              }}
            >
              数学 Ⅲ
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("数学A");
                handleCategoryClose();
              }}
            >
              数学A
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("数学B");
                handleCategoryClose();
              }}
            >
              数学B
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("数学C");
                handleCategoryClose();
              }}
            >
              数学C
            </MenuItem>
          </NestedMenuItem>
          <MenuItem
            onClick={() => {
              props.setCategory("算数/数学");
              handleCategoryClose();
            }}
          >
            上記他
          </MenuItem>
        </NestedMenuItem>
        <NestedMenuItem
          label="理科"
          mainMenuOpen={Boolean(props.anchor)}
          left={props.left}
        >
          <NestedMenuItem
            label="小学校"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("理科/小学校/3年");
                handleCategoryClose();
              }}
            >
              3年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("理科/小学校/4年");
                handleCategoryClose();
              }}
            >
              4年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("理科/小学校/5年");
                handleCategoryClose();
              }}
            >
              5年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("理科/小学校/6年");
                handleCategoryClose();
              }}
            >
              6年
            </MenuItem>
          </NestedMenuItem>
          <NestedMenuItem
            label="中学校"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("理科/中学校/1年");
                handleCategoryClose();
              }}
            >
              1年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("理科/中学校/2年");
                handleCategoryClose();
              }}
            >
              2年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("理科/中学校/3年");
                handleCategoryClose();
              }}
            >
              3年
            </MenuItem>
          </NestedMenuItem>
          <NestedMenuItem
            label="高等学校"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("高校/物理");
                handleCategoryClose();
              }}
            >
              物理
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("高校/化学");
                handleCategoryClose();
              }}
            >
              化学
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("高校/生物");
                handleCategoryClose();
              }}
            >
              生物
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("高校/地学");
                handleCategoryClose();
              }}
            >
              地学
            </MenuItem>
          </NestedMenuItem>
          <MenuItem
            onClick={() => {
              props.setCategory("理科");
              handleCategoryClose();
            }}
          >
            上記他
          </MenuItem>
        </NestedMenuItem>
        <NestedMenuItem
          label="プログラミング"
          mainMenuOpen={Boolean(props.anchor)}
          left={props.left}
        >
          <NestedMenuItem
            label="小学校"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("プログラミング/ロボット");
                handleCategoryClose();
              }}
            >
              ロボット
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("プログラミング/センサー");
                handleCategoryClose();
              }}
            >
              センサー
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("プログラミング/ゲーム");
                handleCategoryClose();
              }}
            >
              ゲーム
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("プログラミング/小学校");
                handleCategoryClose();
              }}
            >
              上記他
            </MenuItem>
          </NestedMenuItem>
          <MenuItem
            onClick={() => {
              props.setCategory("プログラミング");
              handleCategoryClose();
            }}
          >
            上記他
          </MenuItem>
        </NestedMenuItem>
        <NestedMenuItem
          label="技術（中）"
          mainMenuOpen={Boolean(props.anchor)}
          left={props.left}
        >
          <MenuItem
            onClick={() => {
              props.setCategory("技術/材料と加工/中学校");
              handleCategoryClose();
            }}
          >
            材料と加工
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setCategory("技術/生物育成/中学校");
              handleCategoryClose();
            }}
          >
            生物育成
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setCategory("技術/エネルギー変換/中学校");
              handleCategoryClose();
            }}
          >
            エネルギー変換
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setCategory("技術/情報の技術/中学校");
              handleCategoryClose();
            }}
          >
            情報の技術
          </MenuItem>
        </NestedMenuItem>
        <NestedMenuItem
          label="情報（高）"
          mainMenuOpen={Boolean(props.anchor)}
          left={props.left}
        >
          <NestedMenuItem
            label="情報 Ⅰ"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("情報Ⅰ/情報社会");
                handleCategoryClose();
              }}
            >
              情報社会の問題解決
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("情報Ⅰ/コミュニケーション/情報デザイン");
                handleCategoryClose();
              }}
            >
              コミュニケーションと情報デザイン
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("情報Ⅰ/コンピュータ/プログラミング");
                handleCategoryClose();
              }}
            >
              コンピュータとプログラミング
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("情報Ⅰ/情報通信ネットワーク/データの活用");
                handleCategoryClose();
              }}
            >
              情報通信ネットワークとデータの活用
            </MenuItem>
          </NestedMenuItem>
          <NestedMenuItem
            label="情報 Ⅱ"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("情報Ⅱ/情報社会/情報技術");
                handleCategoryClose();
              }}
            >
              情報社会の進展と情報技術
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("情報Ⅱ/コミュニケーション/コンテンツ");
                handleCategoryClose();
              }}
            >
              コミュニケーションとコンテンツ
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("情報Ⅱ/情報/データサイエンス");
                handleCategoryClose();
              }}
            >
              情報とデータサイエンス
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("情報Ⅱ/情報システム/プログラミング");
                handleCategoryClose();
              }}
            >
              情報システムとプログラミング
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("情報Ⅱ/情報技術/活用");
                handleCategoryClose();
              }}
            >
              情報と情報技術を活用した問題発見・解決の探究
            </MenuItem>
          </NestedMenuItem>
          <MenuItem
            onClick={() => {
              props.setCategory("情報");
              handleCategoryClose();
            }}
          >
            上記他
          </MenuItem>

        </NestedMenuItem>
        <NestedMenuItem
          label="英語/外国語"
          mainMenuOpen={Boolean(props.anchor)}
          left={props.left}
        >
          <NestedMenuItem
            label="小学校"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("英語活動/外国語活動/小学校/3年");
                handleCategoryClose();
              }}
            >
              3年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("英語活動/外国語活動/小学校/4年");
                handleCategoryClose();
              }}
            >
              4年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("英語/小学校/5年");
                handleCategoryClose();
              }}
            >
              5年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("英語/小学校/6年");
                handleCategoryClose();
              }}
            >
              6年
            </MenuItem>
          </NestedMenuItem>
          <NestedMenuItem
            label="中学校"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("英語/中学校/1年");
                handleCategoryClose();
              }}
            >
              1年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("英語/中学校/2年");
                handleCategoryClose();
              }}
            >
              2年
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("英語/中学校/3年");
                handleCategoryClose();
              }}
            >
              3年
            </MenuItem>
          </NestedMenuItem>
          <NestedMenuItem
            label="高等学校"
            mainMenuOpen={Boolean(props.anchor)}
            left={props.left}
          >
            <MenuItem
              onClick={() => {
                props.setCategory("英語コミュニケーションⅠ/高等学校");
                handleCategoryClose();
              }}
            >
              英語コミュニケーションⅠ
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("英語コミュニケーションⅡ/高等学校");
                handleCategoryClose();
              }}
            >
              英語コミュニケーションⅡ
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("英語コミュニケーションⅢ/高等学校");
                handleCategoryClose();
              }}
            >
              英語コミュニケーションⅢ
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("論理・表現Ⅰ/英語/高等学校");
                handleCategoryClose();
              }}
            >
              論理・表現Ⅰ
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("論理・表現Ⅱ/英語/高等学校");
                handleCategoryClose();
              }}
            >
              論理・表現Ⅱ
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setCategory("論理・表現Ⅲ/英語/高等学校");
                handleCategoryClose();
              }}
            >
              論理・表現Ⅲ
            </MenuItem>
          </NestedMenuItem>
          <MenuItem
            onClick={() => {
              props.setCategory("英語/外国語");
              handleCategoryClose();
            }}
          >
            上記他
          </MenuItem>
        </NestedMenuItem>
        {props.addOthers ? (
          <MenuItem
            onClick={() => {
              props.setCategory("その他");
              handleCategoryClose();
            }}
          >
            その他
          </MenuItem>
        ) : (
          <></>
        )}
      </Menu>
    </>
  );
};

export default CategoryNestedPulldown;
