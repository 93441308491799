import React, { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { Typography, Card, Grid, ButtonBase, Link } from "@material-ui/core";
import theme_app from "../../Theme";

export interface VideoFeedbackProps {
  title: string;
  channelTitle: string;
  videoId: string;
  nickname: string;
  reviewText: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    cardstyle: {
      margin: theme_app.spacing(4),
      backgroundColor: grey[50],
    },
    vid_title: {
      cursor: "pointer",
      marginTop: theme.spacing(2),
    },
    fb_radio: {
      marginTop: theme.spacing(2),
    },
    centering: {
      textAlign: "center",
    },
    feedbackCard: {
      width: "100%",
      marginLeft: theme_app.spacing(2),
      marginRight: theme_app.spacing(2),
      marginBottom: theme_app.spacing(2),
      padding: theme_app.spacing(2),
    },
  })
);

const VideoFeedbackCard: FC<VideoFeedbackProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const youtubeURL = () => {
    history.push("/video?v=" + props.videoId);
  };

  const [imageUrl, setImageUrl] = useState(
    `https://i.ytimg.com/vi/${props.videoId}/maxresdefault.jpg`
  );
  const img = new Image();
  img.src = `https://i.ytimg.com/vi/${props.videoId}/maxresdefault.jpg`;
  img.onload = (e: any) => {
    if (img.width === 120 && img.height === 90) {
      setImageUrl(`https://i.ytimg.com/vi/${props.videoId}/mqdefault.jpg`);
    } else {
      setImageUrl(`https://i.ytimg.com/vi/${props.videoId}/maxresdefault.jpg`);
    }
  };

  return (
    <Card variant="outlined" className={classes.cardstyle}>
      <Card elevation={0} className={classes.cardstyle}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={8} sm={5}>
            <ButtonBase className={classes.image} onClick={youtubeURL}>
              <img className={classes.image} alt="complex" src={imageUrl} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className={classes.centering}>
              <Typography
                className={classes.vid_title}
                gutterBottom
                variant="h6"
              >
                <Link onClick={youtubeURL} color="inherit">
                  {props.title}
                </Link>
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
                {props.channelTitle}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Card>

      <Grid container justify="center">
        <Grid item xs={10}>
          <Typography style={{ marginLeft: theme_app.spacing(4) }}>
            {props.nickname} さんの感想
          </Typography>
          <Card className={classes.feedbackCard}>{props.reviewText}</Card>
        </Grid>
      </Grid>
    </Card>
  );
};

export default VideoFeedbackCard;
