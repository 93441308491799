// 動画を閲覧するページ
import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Divider,
  Link,
  ButtonBase,
} from "@material-ui/core";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import queryString from "query-string";
import { Rating } from "@material-ui/lab";
import { yellow } from "@material-ui/core/colors";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";

// self modules
import types from "../store/types";
import { FeedbackBar } from "../utils/feedback";
import theme_app from "../Theme";
import { saveVideoReview, getVideoInformation } from "../utils/http-requests";
import { Store } from "../store";
import { videoInformation } from "../utils/types";

const useStyles = makeStyles({
  videoPadding: {
    padding: theme_app.spacing(4, 2),
  },
  videoDescription: {
    padding: theme_app.spacing(0, 2),
  },
  channelTitle: {
    color: "#616161",
    cursor: "pointer",
  },
  channelLink: {
    color: "inherit",
    textDecoration: "inherit",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0,
  },
  videoContainer: {
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%",
  },
  feedback_paper: {
    margin: theme_app.spacing(5, 3),
    backgroundColor: yellow[50],
  },
  centering: {
    textAlign: "center",
  },
  rating: {
    fontSize: theme_app.typography.pxToRem(50),
  },
  rateText: {
    paddingTop: theme_app.spacing(3),
    paddingBottom: theme_app.spacing(1),
    color: "#616161",
  },
  reviewForm: {
    paddingBottom: theme_app.spacing(3),
  },
  textCheckContainer: {
    textAlign: "center",
    display: "flex",
  },
  textCheck: {
    verticalAlign: "middle",
  },
  saveButton: {
    marginBottom: theme_app.spacing(2),
  },
  moveLink: {
    color: "#616161",
    cursor: "pointer",
    padding: theme_app.spacing(2),
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const WatchVideoPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);
  const [videotag, setVideoTag] = useState("");
  const [rating, setRating] = React.useState<number | null>(null);
  const [review, setReview] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [viewTime, setViewTime] = useState(0);
  const [viewStartTime, setViewStartTime] = useState(0);
  const [videoTitle, setVideoTitle] = useState("");
  const [channelName, setChannelName] = useState("");
  const [channelId, setChannelId] = useState("");
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  const [isRecommended, setIsRecommended] = useState(false);

  // youtubeの動画のタグをURLパラメータから取得
  useEffect(() => {
    const qs = queryString.parse(history.location.search);
    setVideoTag("https://www.youtube.com/embed/" + String(qs.v));
    // レコメンドされた動画ならパラメータをtrueに
    if (String(qs.type) === "recommend") {
      setIsRecommended(true);
    }
  }, []);

  // 動画のタグを取得したら反映
  useEffect(() => {
    const f = async () => {
      const qs = queryString.parse(history.location.search);
      const videoData: videoInformation = await getVideoInformation(
        String(qs.v)
      );
      setVideoTitle(videoData.title);
      setChannelName(videoData.channelTitle);
      setChannelId(videoData.channelId);
      setRating(videoData.rating || null);
      setReview(videoData.reviewText || "");
      setIsFavorite(videoData.isFavorite || false);
      setIsPublic(videoData.isPublic || false);
      setViewTime(videoData.viewTime || 0);
      setIsSaved(true);
    };
    if (videotag !== "") {
      f();
    }
    setViewStartTime(Date.now());
  }, [videotag]);

  // 感想入力のハンドラ
  const handleReviewInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setReview(e.target.value);
    if (isSaved) {
      setIsSaved(false);
    }
  };

  // お気に入りボタンのハンドラ
  const handleFavorite = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsFavorite(!isFavorite);
    if (isSaved) {
      setIsSaved(false);
    }
  };

  // 公開ボタンのハンドラ
  const handlePublic = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsPublic(!isPublic);
    if (isSaved) {
      setIsSaved(false);
    }
  };

  // feedback bar用のハンドラ
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 保存ボタンのハンドラ
  const handleSaveButton = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      const videoId = String(queryString.parse(history.location.search).v);
      await saveVideoReview(
        videoId,
        rating || 0,
        review,
        isFavorite,
        isPublic,
        Date.now() - viewStartTime + viewTime,
        isRecommended ? state.recommendCategory : undefined,
        isRecommended ? state.recommendReview : undefined
      );
      setIsSaved(true);
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "動画を保存しました。",
        type: "success",
      });
    } catch (error) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "動画の感想を保存できませんでした。時間をおいてお試しください。",
        type: "error",
      });
    }
  };

  // チャンネルタイトルクリックでyoutubeのチャンネルを開く
  const handleChannelTitle = (e: React.MouseEvent) => {
    e.preventDefault();
    window.open("https://www.youtube.com/channel/" + channelId);
  };

  // FIXME: 何でここでUser Authをアップデートしてるか謎
  const changeRoute = (url: string) => {
    history.push(url);
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <div className={classes.videoPadding}>
            <div className={classes.videoContainer}>
              <iframe
                className={classes.video}
                title="asdf"
                src={videotag}
                frameBorder="none"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                scrolling="no"
              />
            </div>
          </div>
          <div className={classes.videoDescription}>
            <Typography variant="h5">{videoTitle}</Typography>
            <Typography className={classes.channelTitle}>
              <Link
                className={classes.channelLink}
                onClick={handleChannelTitle}
              >
                {channelName}
              </Link>
            </Typography>
            <Divider />
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={4} lg={4}>
          <Paper className={classes.feedback_paper} elevation={1}>
            <div className={classes.centering}>
              <Typography variant="h5" className={classes.rateText}>
                評価
              </Typography>
              <Rating
                name="customized-empty"
                defaultValue={2}
                max={4}
                precision={1}
                classes={{ sizeLarge: classes.rating }}
                size="large"
                value={rating}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                onChange={(event, newValue) => {
                  setRating(newValue);
                  if (isSaved) {
                    setIsSaved(false);
                  }
                }}
              />
              <Typography variant="h5" className={classes.rateText}>
                感想
              </Typography>
              <Grid container justify="center" className={classes.reviewForm}>
                <Grid item xs={10}>
                  <TextField
                    multiline
                    rows={12}
                    variant="outlined"
                    fullWidth
                    value={review}
                    onChange={handleReviewInput}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" className={classes.reviewForm}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<FavoriteBorder />}
                        checkedIcon={<Favorite />}
                        name="checkedH"
                        checked={isFavorite}
                        onClick={handleFavorite}
                      />
                    }
                    label="お気に入り登録"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={isPublic} onClick={handlePublic} />
                    }
                    label="公開する"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item xs={6}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    disabled={
                      !isSaved && rating && review !== "" ? false : true
                    }
                    onClick={handleSaveButton}
                  >
                    保存
                  </Button>
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={6}>
                  <ButtonBase
                    onClick={() => {
                      changeRoute("/recommend");
                    }}
                  >
                    <Typography className={classes.moveLink}>
                      レコメンドページ
                    </Typography>
                  </ButtonBase>
                </Grid>
                <Grid item xs={6}>
                  <ButtonBase
                    onClick={() => {
                      changeRoute("/mypage");
                    }}
                  >
                    <Typography className={classes.moveLink}>
                      マイページ
                    </Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <FeedbackBar {...feedbackInfo} handleClose={handleClose} />
    </>
  );
};

export default WatchVideoPage;
